.login-wrapper {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-wrapper .wrapper-card {
  width: 308px;
  min-width: 308px;
  background: none;
  color: #fff;
}

.login-wrapper .card-img {
  width: auto;
  margin: 0 auto;
  cursor: pointer;
}

.login-wrapper input,
.login-wrapper input:focus,
.login-wrapper input:active {
  outline: 0;
  box-shadow: none;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #fff;
  background: none;
  color: #fff;
  font-weight: 500;
  padding: 0;
  height: calc(1.5rem + 2px);
}

.login-wrapper .form-control.is-valid:focus {
  box-shadow: none;
}

.login-wrapper .nav-tabs .nav-item.show .nav-link,
.login-wrapper .nav-tabs .nav-link,
.login-wrapper .nav-tabs .nav-link.active {
  color: #313a40;
  background: none;
  border: none;
  border-bottom: 2px solid black;
  padding: 0.2em 0;
  margin: 0 0.8em;
}

.login-wrapper .nav-tabs .nav-link {
  border: none;
}

.form-icon {
  position: relative;
  top: 2.8rem;
}
.login-page .form-label {
  color: #313a40;
  text-transform: uppercase;
  height: 19px;
  width: 48px;
  color: #313a40;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.46px;
  line-height: 19px;
  font-size: 0.8rem;
}

.invalid-tooltip,
.valid-tooltip {
  position: relative;
}

.invalid-tooltip.show,
.valid-tooltip.show {
  display: block;
}

.login-page .submit-button {
  height: 29px;
  width: 100px !important;
  background-color: #313a40 !important;
  color: #fffaf0 !important;
  color: #fffaf0;
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.8px;
  line-height: 16px;
  text-align: center;
}

.login-page .poetry-maintext {
  color: #fffaf0;
  font-family: "Avenir Next";
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 1.83px;
  line-height: 30px;
  text-align: center;
}

.login-page .login-navbar {
  background-color: #26466d;
  color: #fffaf0;
}
.login-page .App .navbar-light.navbar .nav-item.dropdown .nav-link {
  color: #fffaf0;
}

.login-page .navbar-light .navbar-brand {
  color: #fffaf0;
  font-family: "Avenir Next";
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 1.7px;
}

.login-page .App .navbar {
  padding: 10px !important;
}

.login-page .create-account-text {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 27px;
  text-align: left;
}

.login-page .create-account-subtext {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 20px;
  text-align: left;
}

.login-page .login-button {
  border-radius: 2px;
  background-color: #fffaf0;
  color: #26466d;
  font-family: "Avenir Next";
  font-weight: 600;
  letter-spacing: 0.59px;
}

.login-page .login-button:hover {
  color: #fffaf0;
  background-color: #26466d;
}

.App .navbar .nav-item:hover {
  background-color: #26466d !important;
}
.login-page .signup-button {
  border-radius: 2px !important;
  color: #fffaf0;
  font-family: "Avenir Next";
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 14px;
  text-align: center;
  background-color: #26466d !important;
  margin-top: 2rem;
}

.login-page input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #26466d !important;
}
.login-page input:focus {
  border-color: green !important;
}

.login-page .half-width {
  width: 50%;
}

.other-options {
  color: #26466d;
  font-family: "Avenir Next";
  font-weight: 600;
  letter-spacing: 1.3px;
  line-height: 18px;
  text-align: left;
}

.small-text {
  font-size: 12px;
}

.login-wrapper input,
.login-wrapper input:focus,
.login-wrapper input:active {
  color: black;
}

.login-navbar {
  padding-top: 0.5em;
}
.register-form .form-group {
  margin-bottom: 2rem;
}

.forget-password {
  color: #00ff33;
  font-size: 12px;
}
