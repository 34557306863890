.profile-page.page-wrapper {
    margin-top: 0;
    padding-top: 0;
}

.profile-banner-wrapper {
    position: relative;
    width: 100%;
    height: 350px;
    overflow: hidden;
}

.profile-banner-wrapper img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
}

.profile-header {
    display: flex;
    position: relative;
}

.profile-image {
    position: relative;
    width: 200px;
    height: 200px;
    margin-top: -125px;
    overflow: hidden;
    border-radius: 50%;

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}



.profile-info {
    flex: 1;
    margin-top: 20px;
    margin-left: 20px;
}

.profile-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #26466d;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.profile-following {
    display: flex;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    letter-spacing: 0.5px;
}

.profile-following li+li {
    margin-left: 20px;
}

.profile-following li span {
    display: inline-block;
    margin-right: 5px;
    font-weight: 500;
    color: #26466d;
}

.profile-groups {
    .group-list-item {
        width: 49%;
        min-height: 100px;

        &:nth-child(n + 3) {
            margin-top: 20px;
        }

        a {
            color: #26466d;

            &:hover {
                color: #223e61;
            }
        }
    }

    .add-group {
        position: relative;
        min-height: 50;
        cursor: pointer;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #26466d;
        }

        &::before {
            width: 2px;
            height: 25px;
        }

        &::after {
            width: 25px;
            height: 2px;
        }
    }
}

.group-form {
    .member-field {
        display: flex;
    }
}

.playlist-list {
    justify-content: start;

    .playlist-list-item {
        width: 32%;
        display: block;
        margin-left: 2%;
        background-color: #fff;
        cursor: pointer;

        &:nth-child(3n + 1) {
            margin-left: 0;
        }

        .playlist-images {
            position: relative;
            width: 100%;
            margin-bottom: 0;
            padding-bottom: 100%;
            list-style: none;
            font-size: 0;
            border-bottom: solid 1px #efefef;

            a {
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
            }

            img {
                position: absolute;
                width: 50%;
                height: 50%;
                font-size: 0;
                transform: translate(0);

                &:nth-child(1) {
                    top: 0;
                    left: 0;
                }

                &:nth-child(2) {
                    top: 0;
                    left: 50%;
                }

                &:nth-child(3) {
                    top: 50%;
                    left: 0;
                }

                &:nth-child(4) {
                    top: 50%;
                    left: 50%;
                }
            }
        }
        .item-header {
            display: flex;
            align-items: center;
            padding: 10px;

            .item-info {
                margin-left: 0;

                h5 {
                    margin: 0;
                    padding: 0;

                    a {
                        color: #26466d;

                        &:hover {
                            color: #223e61;
                        }
                    }
                }
            }
        }
    }

    .playlist-add {
        position: relative;
        min-height: 100px;
        cursor: pointer;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #26466d;
        }

        &::before {
            width: 2px;
            height: 25px;
        }

        &::after {
            width: 25px;
            height: 2px;
        }
    }
}

.profile-manage-modal {
    img {
        width: 100%;
    }

    .main-photo {
        position: relative;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .member-field {
        display: flex;

        .button {
            height: 38px;
            line-height: 38px;
        }
    }
}

.profile-manage-modal label {
    font-weight: bold;
  }


.recitation-edit-manage-modal {
    .member-field {
        display: flex;

        .button {
            height: 38px;
            line-height: 38px;
        }
    }
}

.recitation-edit-manage-modal label {
    font-weight: bold;
  }

  @media (max-width: 767px) { /* Change the size for mobile devices */
    .profile-image {
        margin-top: -220px;
        position:absolute;
        width: 200px; /* Set width in percentage */
        height: 200px;
    }
    .profile-groups {
        .group-list-item {
            width: 100%;
            min-height: 50px;
    
            &:nth-child(n + 2) {
                margin-top: 20px;
            }
    
            a {
                color: #26466d;
    
                &:hover {
                    color: #223e61;
                }
            }
        }
    
        .add-group {
            position: relative;
            min-height: 50;
            cursor: pointer;
    
            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: #26466d;
            }
    
            &::before {
                width: 2px;
                height: 25px;
            }
    
            &::after {
                width: 25px;
                height: 2px;
            }
        }
    }

    .playlist-list {
        justify-content: start;
    
        .playlist-list-item {
            width: 100%;
            display: block;
            margin-top: 5%;
            background-color: #fff;
            cursor: pointer;
    
            
    
            .playlist-images {
                position: relative;
                width: 100%;
                margin-bottom: 0;
                padding-bottom: 100%;
                list-style: none;
                font-size: 0;
                border-bottom: solid 1px #efefef;
    
                a {
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                }
    
                img {
                    position: absolute;
                    width: 50%;
                    height: 50%;
                    font-size: 0;
                    transform: translate(0);
    
                    &:nth-child(1) {
                        top: 0;
                        left: 0;
                    }
    
                    &:nth-child(2) {
                        top: 0;
                        left: 50%;
                    }
    
                    &:nth-child(3) {
                        top: 50%;
                        left: 0;
                    }
    
                    &:nth-child(4) {
                        top: 50%;
                        left: 50%;
                    }
                }
            }
            .item-header {
                display: flex;
                align-items: center;
                padding: 10px;
    
                .item-info {
                    margin-left: 0;
    
                    h5 {
                        margin: 0;
                        padding: 0;
    
                        a {
                            color: #26466d;
    
                            &:hover {
                                color: #223e61;
                            }
                        }
                    }
                }
            }
        }
    
        .playlist-add {
            position: relative;
            min-height: 100px;
            cursor: pointer;
    
            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: #26466d;
            }
    
            &::before {
                width: 2px;
                height: 25px;
            }
    
            &::after {
                width: 25px;
                height: 2px;
            }
        }
    }
}