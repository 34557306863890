.App .navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 1rem;
  color: #fff;
  z-index: 1000;

  .navbar-nav {
    display: flex;
    align-items: center;
  }
}

.App .navbar-light .navbar-nav .nav-item .nav-link {
  color: #fff;
}

.App .navbar-light.navbar .nav-item .nav-link:hover {
  color: #000;
}

.App .navbar-light.navbar .nav-item.dropdown .nav-link {
  color: #000;
}

.App .navbar-light.navbar .nav-item.dropdown .dropdown-item {
  padding: 8px 20px;
}

.App .navbar .nav-item {
  margin: 0 5px;
}

.App .navbar .nav-item:hover {
  background-color: #fffaf0;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.App .underline:after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 0;
  background: transparent;
  -webkit-transition: width 0.5s ease, background-color 0.5s ease;
  -moz-transition: width 0.5s ease, background-color 0.5s ease;
  -ms-transition: width 0.5s ease, background-color 0.5s ease;
  -o-transition: width 0.5s ease, background-color 0.5s ease;
  transition: width 0.5s ease, background-color 0.5s ease;
}

.App .navbar .underline:hover:after {
  width: 100%;
  background: #000;
}

.App .navbar-brand .logo {
  height: 56px;
}

.App {
  .navbar {
    height: 80px;

    .logo {
      display: flex;
      align-items: center;

      span {
        font-family: "Avenir Next";
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1.4px;
        line-height: 1;
      }

      img {
        font-size: 0;
        height: 44px;
        margin-right: 15px;
      }
    }
  }

  .create-btn {
    a {
      color: #000;
    }
  }
}

.App .navbar .nav-item.profile {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 0 auto;
}

.App .nav-profile {
  width: 40px;
  height: 40px;
  position: relative;
  top: 2.75px;
  left: -2px;
  border-radius: 50%;
}

.App .navbar .search {
  flex-grow: 2;
  max-width: 400px;
}

.App .navbar .svg-inline--fa {
  font-size: 1.1rem;
}

.rbt-input-hint-container:focus {
  outline: none;
}

input.rbt-input-main.form-control.rbt-input,
input.rbt-input-main.form-control.rbt-input:focus,
input.rbt-input-main.form-control.rbt-input:active {
  background-color: transparent;
  color: #fff;
  border: 0;
  border-bottom: 1.5px solid white;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  /* visibility: hidden;
  opacity: 0;
  width:0;  */
}
input.rbt-input-main.form-control.rbt-input.show,
input.rbt-input-main.form-control.rbt-input.show:focus,
input.rbt-input-main.form-control.rbt-input.show:active {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

input.rbt-input-main.form-control.rbt-input::placeholder {
  color: #fff;
  font-weight: 500;
}

.new-recording {
  border: 1px solid white;
  font-size: 0.9rem;
  position: relative;
  top: 4px;
}

.login-button {
  border-radius: 2px;
  background-color: #fffaf0;
  color: #26466d;
  font-family: "Avenir Next";
  font-weight: 600;
  letter-spacing: 0.59px;
}

.login-button:hover {
  text-decoration: none;
}

.header--text-input {
  background-color: white !important;
  padding: 15px !important;
  border-radius: 10px !important;
}

.create-btn {
  .dropdown-toggle {
    background-color: #31C27D !important;
    border: 0;
  }
}
