.notifications-content {
  max-width: 650px;
}

.notifications-list {
  margin-top: 20px;
  list-style: none;
}

.notifications-list-item + .notifications-list-item {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
}

.notifications-list .notification-user {
  display: flex;
  align-items: center;
  width: 100%;
}

.notification-user .user-image {
  position: relative;
  width: 60px;
  height: 60px;
}

.notification-user .user-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.notification-user .user-info {
  flex: 1;
  margin: 0 20px;
}

.notification-user .user-info p {
  margin-bottom: 10px;
}

.notification-user .user-info time {
  font-size: 0.9em;
  color: #777;
}
.notification-user .user-link {
  justify-items: flex-end;
}
