.rc-slider {
  border-radius: 6px;
  height: 14px;
  padding: 5px 0;
  position: relative;
  touch-action: none;
  width: 100%;
}
.rc-slider,
.rc-slider * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}
.rc-slider-rail {
  background-color: #e9e9e9;
  width: 100%;
}
.rc-slider-rail,
.rc-slider-track {
  border-radius: 6px;
  height: 4px;
  position: absolute;
}
.rc-slider-track {
  background-color: #abe2fb;
  left: 0;
}
.rc-slider-handle {
  background-color: #fff;
  border: 2px solid #96dbfa;
  border-radius: 50%;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  height: 14px;
  margin-top: -5px;
  position: absolute;
  touch-action: pan-x;
  width: 14px;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
}
.rc-slider-handle:focus {
  outline: none;
}
.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: #57c5f7;
}
.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.rc-slider-mark {
  font-size: 12px;
  left: 0;
  position: absolute;
  top: 18px;
  width: 100%;
}
.rc-slider-mark-text {
  color: #999;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  text-align: center;
  vertical-align: middle;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  background: transparent;
  height: 4px;
  position: absolute;
  width: 100%;
}
.rc-slider-dot {
  background-color: #fff;
  border: 2px solid #e9e9e9;
  border-radius: 50%;
  bottom: -2px;
  cursor: pointer;
  height: 8px;
  margin-left: -4px;
  position: absolute;
  vertical-align: middle;
  width: 8px;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-dot-reverse {
  margin-right: -4px;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-dot,
.rc-slider-disabled .rc-slider-handle {
  background-color: #fff;
  border-color: #ccc;
  box-shadow: none;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-dot,
.rc-slider-disabled .rc-slider-mark-text {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  height: 100%;
  padding: 0 5px;
  width: 14px;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  bottom: 0;
  left: 5px;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  height: 100%;
  left: 18px;
  top: 0;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-appear,
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
  display: block !important;
}
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active,
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-appear,
.rc-slider-tooltip-zoom-down-enter {
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transform: scale(0);
}
.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform: scale(0);
    transform-origin: 50% 100%;
  }
  to {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  to {
    opacity: 0;
    transform: scale(0);
    transform-origin: 50% 100%;
  }
}
.rc-slider-tooltip {
  left: -9999px;
  position: absolute;
  top: -9999px;
  visibility: visible;
}
.rc-slider-tooltip,
.rc-slider-tooltip * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px;
}
.rc-slider-tooltip-inner {
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
  color: #fff;
  font-size: 12px;
  height: 24px;
  line-height: 1;
  min-width: 24px;
  padding: 6px 2px;
  text-align: center;
  text-decoration: none;
}
.rc-slider-tooltip-arrow {
  border-color: transparent;
  border-style: solid;
  height: 0;
  position: absolute;
  width: 0;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #6c6c6c;
  border-width: 4px 4px 0;
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
}
.rc-switch {
  background-color: #ccc;
  border: 1px solid #ccc;
  border-radius: 20px 20px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: 22px;
  line-height: 20px;
  padding: 0;
  position: relative;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  vertical-align: middle;
  width: 44px;
}
.rc-switch-inner {
  color: #fff;
  font-size: 12px;
  left: 24px;
  position: absolute;
  top: 0;
}
.rc-switch:after {
  animation-duration: 0.3s;
  animation-name: rcSwitchOff;
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  background-color: #fff;
  border-radius: 50% 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  content: " ";
  cursor: pointer;
  height: 18px;
  left: 2px;
  position: absolute;
  top: 1px;
  transform: scale(1);
  transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  width: 18px;
}
.rc-switch:hover:after {
  animation-name: rcSwitchOn;
  transform: scale(1.1);
}
.rc-switch:focus {
  box-shadow: 0 0 0 2px #d5f1fd;
  outline: none;
}
.rc-switch-checked {
  background-color: #87d068;
  border: 1px solid #87d068;
}
.rc-switch-checked .rc-switch-inner {
  left: 6px;
}
.rc-switch-checked:after {
  left: 22px;
}
.rc-switch-disabled {
  background: #ccc;
  border-color: #ccc;
  cursor: no-drop;
}
.rc-switch-disabled:after {
  animation-name: none;
  background: #9e9e9e;
  cursor: no-drop;
}
.rc-switch-disabled:hover:after {
  animation-name: none;
  transform: scale(1);
}
.rc-switch-label {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  padding-left: 10px;
  pointer-events: none;
  user-select: text;
  vertical-align: middle;
  white-space: normal;
}
@keyframes rcSwitchOn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  to {
    transform: scale(1.1);
  }
}
@keyframes rcSwitchOff {
  0% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}
.react-jinke-music-player-main:focus {
  outline: none;
}
.react-jinke-music-player-main li,
.react-jinke-music-player-main ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.react-jinke-music-player-main * {
  box-sizing: border-box;
}
.react-jinke-music-player-main .text-center {
  text-align: center;
}
.react-jinke-music-player-main .hidden {
  display: none !important;
}
.react-jinke-music-player-main .loading {
  animation: audioLoading 1s linear infinite;
  display: inline-flex;
}
.react-jinke-music-player-main .loading svg {
  color: #31c27c;
  font-size: 24px;
}
.react-jinke-music-player-main .translate {
  animation: translate 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-main .scale {
  animation: scaleTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
@keyframes playing {
  to {
    transform: rotateX(1turn);
  }
}
@keyframes coverReset {
  to {
    transform: rotate(0deg);
  }
}
@keyframes audioLoading {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes scale {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.5);
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}
@keyframes scaleTo {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes scaleFrom {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes imgRotate {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes fromTo {
  0% {
    transform: scale(1) translate3d(0, 110%, 0);
  }
  to {
    transform: scale(1) translateZ(0);
  }
}
@keyframes fromOut {
  0% {
    transform: scale(1) translateZ(0);
  }
  to {
    transform: scale(1) translate3d(0, 110%, 0);
  }
}
@keyframes fromDown {
  0% {
    transform: scale(1) translate3d(0, -110%, 0);
  }
  to {
    transform: scale(1) translateZ(0);
  }
}
@keyframes translate {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes remove {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}
.react-jinke-music-player-main .img-rotate-pause {
  animation-play-state: paused !important;
}
.react-jinke-music-player-main .img-rotate-reset {
  animation: coverReset 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards !important;
}
.react-jinke-music-player-mobile {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 30px;
  margin-top: 70px;
  overflow: hidden;
  padding: 60px;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}
.react-jinke-music-player-mobile > .group {
  flex: 1 1 auto;
}
.react-jinke-music-player-mobile .show {
  animation: mobile-bg-show 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile .hide {
  animation: mobile-bg-hide 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile-play-model-tip {
  align-items: center;
  background-color: #31c27c;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  color: #fff;
  display: flex;
  height: 35px;
  left: 30px;
  margin-top: 70px;
  line-height: 35px;
  padding: 0 60px;
  position: fixed;
  top: 0;
  transform: translate3d(0, -105%, 0);
  transition: transform 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 100%;
  z-index: 1000;
}
.react-jinke-music-player-mobile-play-model-tip-title {
  margin-right: 12px;
}
.react-jinke-music-player-mobile-play-model-tip-title svg {
  animation: none !important;
  vertical-align: text-bottom !important;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-mobile-play-model-tip-title svg {
    color: #fff !important;
    font-size: 19px;
  }
}
.react-jinke-music-player-mobile-play-model-tip-title .loop-btn {
  display: flex;
}
.react-jinke-music-player-mobile-play-model-tip-text {
  font-size: 14px;
}
.react-jinke-music-player-mobile-play-model-tip.show {
  transform: translateZ(0);
}
.react-jinke-music-player-mobile-header {
  align-items: center;
  animation: fromDown 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  display: flex;
  justify-content: center;
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
}
.react-jinke-music-player-mobile-header-title {
  font-size: 20px;
  overflow: hidden;
  padding: 0 30px;
  text-align: center;
  text-overflow: ellipsis;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  white-space: nowrap;
}
.react-jinke-music-player-mobile-header .item {
  display: inline-flex;
  width: 50px;
}
.react-jinke-music-player-mobile-header-right {
  color: hsla(0, 0%, 100%, 0.6);
  cursor: pointer;
  position: absolute;
  right: 0;
}
.react-jinke-music-player-mobile-header-right svg {
  font-size: 25px;
}
.react-jinke-music-player-mobile-singer {
  animation: fromDown 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  padding: 12px 0;
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-mobile-singer {
    padding: 0;
  }
}
.react-jinke-music-player-mobile-singer-name {
  font-size: 14px;
  position: relative;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-mobile-singer-name:after,
.react-jinke-music-player-mobile-singer-name:before {
  background-color: hsla(0, 0%, 100%, 0.6);
  border-radius: 2px;
  content: "";
  height: 1px;
  position: absolute;
  top: 9px;
  transition: background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 16px;
}
.react-jinke-music-player-mobile-singer-name:after {
  left: -25px;
}
.react-jinke-music-player-mobile-singer-name:before {
  right: -25px;
}
.react-jinke-music-player-mobile-cover {
  animation: fromTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  border: 5px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.1);
  height: 300px;
  margin: 15px auto;
  overflow: hidden;
  transition: box-shadow, border 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 300px;
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-mobile-cover {
    height: 230px;
    margin: 10px auto;
    width: 230px;
  }
}
.react-jinke-music-player-mobile-cover .cover {
  animation: imgRotate 30s linear infinite;
  object-fit: cover;
  width: 100%;
}
.react-jinke-music-player-mobile-progress {
  align-items: center;
  display: flex;
  justify-content: space-around;
}
.react-jinke-music-player-mobile-progress .current-time,
.react-jinke-music-player-mobile-progress .duration {
  color: hsla(0, 0%, 100%, 0.6);
  display: inline-flex;
  font-size: 12px;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 55px;
}
.react-jinke-music-player-mobile-progress .current-time {
  margin-right: 5px;
}
.react-jinke-music-player-mobile-progress .duration {
  justify-content: flex-end;
  margin-left: 5px;
}
.react-jinke-music-player-mobile-progress .progress-bar {
  flex: 1 1 auto;
}
.react-jinke-music-player-mobile-progress .rc-slider-rail {
  background-color: hsla(0, 0%, 100%, 0.6);
}
.react-jinke-music-player-mobile-progress .rc-slider-handle,
.react-jinke-music-player-mobile-progress .rc-slider-track {
  background-color: #31c27c;
}
.react-jinke-music-player-mobile-progress .rc-slider-handle {
  border: 2px solid #fff;
}
.react-jinke-music-player-mobile-progress .rc-slider-handle:active {
  box-shadow: 0 0 2px #31c27c;
}
.react-jinke-music-player-mobile-progress-bar {
  display: flex;
  position: relative;
  width: 100%;
}
.react-jinke-music-player-mobile-progress-bar .progress-load-bar {
  background-color: rgba(0, 0, 0, 0.06);
  height: 4px;
  left: 0;
  position: absolute;
  top: 5px;
  transition: width, background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 0;
  z-index: 77;
}
.react-jinke-music-player-mobile-switch {
  animation: fromDown 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile-toggle {
  padding: 17px 0;
}
.react-jinke-music-player-mobile-toggle .group {
  cursor: pointer;
}
.react-jinke-music-player-mobile-toggle .group svg {
  font-size: 40px;
}
.react-jinke-music-player-mobile-toggle .play-btn {
  padding: 0 40px;
}
.react-jinke-music-player-mobile-toggle .play-btn svg {
  font-size: 45px;
}
.react-jinke-music-player-mobile-toggle .loading-icon {
  padding: 0 40px;
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-mobile-toggle {
    padding: 10px 0;
  }
  .react-jinke-music-player-mobile-toggle > .group svg {
    font-size: 32px;
  }
  .react-jinke-music-player-mobile-toggle .play-btn svg {
    font-size: 40px;
  }
}
.react-jinke-music-player-mobile-operation,
.react-jinke-music-player-mobile-progress,
.react-jinke-music-player-mobile-toggle {
  animation: fromTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile-operation .items {
  align-items: center;
  display: flex;
  justify-content: space-around;
}
.react-jinke-music-player-mobile-operation .items .item {
  cursor: pointer;
  flex: 1;
  text-align: center;
}
.react-jinke-music-player-mobile-operation .items .item svg {
  color: hsla(0, 0%, 100%, 0.6);
}
@keyframes mobile-bg-show {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mobile-bg-hide {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.audio-lists-panel-sortable-highlight-bg {
  background-color: rgba(49, 194, 124, 0.15) !important;
}
.audio-lists-panel {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px 4px 0 0;
  bottom: 80px;
  color: hsla(0, 0%, 100%, 0.8);
  display: none\9;
  height: 410px;
  overflow: hidden;
  position: fixed;
  right: 33px;
  transform: scale(0);
  transform-origin: right bottom;
  transition: background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 480px;
  z-index: 999;
}
.audio-lists-panel svg {
  font-size: 24px;
}
.audio-lists-panel.show {
  animation: scaleTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  display: block\9;
}
.audio-lists-panel.hide {
  animation: scaleFrom 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  display: none\9;
}
.audio-lists-panel-mobile {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 0;
  bottom: 0;
  height: auto !important;
  left: 30px;
  margin-top: 70px;

  padding: 60px;
  right: 0;
  top: 0;
  transform-origin: bottom center;
  width: 100% !important;
}
.audio-lists-panel-mobile.show {
  animation: fromTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  display: block\9;
}
.audio-lists-panel-mobile.hide {
  animation: fromOut 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  display: none\9;
}
.audio-lists-panel-mobile
  .audio-item:not(.audio-lists-panel-sortable-highlight-bg) {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
.audio-lists-panel-mobile
  .audio-item:not(.audio-lists-panel-sortable-highlight-bg).playing {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.audio-lists-panel-mobile .audio-lists-panel-content {
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 50px) !important;
  transform-origin: bottom center;
  width: 100% !important;
}
.audio-lists-panel-header {
  border-bottom: 1px solid rgba(3, 3, 3, 0.75);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: background-color,
    border-bottom 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-header-close-btn,
.audio-lists-panel-header-delete-btn {
  cursor: pointer;
  display: flex;
}
.audio-lists-panel-header-delete-btn svg {
  font-size: 21px;
}
@media screen and (max-width: 767px) {
  .audio-lists-panel-header-delete-btn svg {
    font-size: 19px;
  }
}
@media screen and (min-width: 768px) {
  .audio-lists-panel-header-close-btn:hover svg {
    animation: imgRotate 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  }
}
.audio-lists-panel-header-line {
  background: #fff;
  height: 20px;
  margin: 0 10px;
  width: 1px;
}
.audio-lists-panel-header-title {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  margin: 0;
  padding: 0 20px;
  text-align: left;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-header-num {
  margin-left: 10px;
}
.audio-lists-panel-header-actions {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.audio-lists-panel-content {
  height: 359px;
  overflow-x: hidden;
  overflow-y: auto;
}
.audio-lists-panel-content.no-content {
  align-items: center;
  display: flex;
  justify-content: center;
}
.audio-lists-panel-content.no-content > span {
  display: flex;
}
.audio-lists-panel-content .no-data {
  margin-left: 10px;
}
.audio-lists-panel-content .audio-item {
  align-items: center;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  line-height: 40px;
  padding: 3px 20px;
  transition: background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-content .audio-item:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}
.audio-lists-panel-content .audio-item.playing {
  background-color: rgba(0, 0, 0, 0.35);
}
.audio-lists-panel-content .audio-item.playing,
.audio-lists-panel-content .audio-item.playing svg {
  color: #31c27c;
}
.audio-lists-panel-content .audio-item.remove {
  animation: remove 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.audio-lists-panel-content .audio-item .player-icons {
  display: inline-flex;
  width: 30px;
}
.audio-lists-panel-content .audio-item .player-icons .loading {
  animation: audioLoading 1s linear infinite;
}
.audio-lists-panel-content .audio-item:active,
.audio-lists-panel-content .audio-item:hover {
  background-color: rgba(0, 0, 0, 0.35);
}
.audio-lists-panel-content
  .audio-item:active
  .group:not([class=".player-delete"])
  svg,
.audio-lists-panel-content
  .audio-item:hover
  .group:not([class=".player-delete"])
  svg {
  color: #31c27c;
}
.audio-lists-panel-content .audio-item .group {
  display: inline-flex;
}
.audio-lists-panel-content .audio-item .player-name {
  flex: 1;
  padding: 0 20px 0 10px;
}
.audio-lists-panel-content .audio-item .player-name,
.audio-lists-panel-content .audio-item .player-singer {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  white-space: nowrap;
}
.audio-lists-panel-content .audio-item .player-singer {
  font-size: 12px;
  width: 85px;
}
.audio-lists-panel-content .audio-item .player-delete {
  justify-content: flex-end;
  text-align: right;
  width: 30px;
}
.audio-lists-panel-content .audio-item .player-delete:hover svg {
  animation: imgRotate 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main {
  font-family: inherit;
  touch-action: none;
}
.react-jinke-music-player-main ::-webkit-scrollbar-thumb {
  background-color: #31c27c;
  height: 20px;
  opacity: 0.5;
}
.react-jinke-music-player-main ::-webkit-scrollbar {
  background-color: #f7f8fa;
  width: 8px;
}
.react-jinke-music-player-main .rc-switch:focus {
  box-shadow: none;
}
.react-jinke-music-player-main .lyric-btn svg {
  font-size: 20px;
}
.react-jinke-music-player-main .lyric-btn-active,
.react-jinke-music-player-main .lyric-btn-active svg {
  color: #31c27c !important;
}
.react-jinke-music-player-main .music-player-lyric {
  background: transparent;
  bottom: 100px;
  color: #31c27c;
  cursor: move;
  font-size: 36px;
  left: 0;
  position: fixed;
  text-align: center;
  text-shadow: 0 1px 1px hsla(0, 0%, 100%, 0.05);
  transition: box-shadow 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 100%;
  z-index: 998;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main .music-player-lyric {
    font-size: 16px;
    z-index: 999;
  }
}
.react-jinke-music-player-main .play-mode-title {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 80px;
  color: #fff;
  line-height: 1.5;
  opacity: 0;
  padding: 5px 20px;
  pointer-events: none;
  position: fixed;
  right: 72px;
  text-align: center;
  transform: translate3d(100%, 0, 0);
  transform-origin: bottom center;
  transition: all 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  user-select: none;
  visibility: hidden;
  z-index: 1000;
}
.react-jinke-music-player-main .play-mode-title.play-mode-title-visible {
  opacity: 1;
  pointer-events: all;
  transform: translateZ(0);
  visibility: visible;
}
.react-jinke-music-player-main .glass-bg-container {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(80px);
  height: 300%;
  left: 0;
  position: absolute;
  top: 0;
  width: 300%;
  z-index: -1;
}
.react-jinke-music-player-main .glass-bg {
  backdrop-filter: blur(10px);
}
.react-jinke-music-player-main svg {
  font-size: 24px;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main svg:active,
.react-jinke-music-player-main svg:hover {
  color: #31c27c;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main svg {
    font-size: 22px;
  }
}
.react-jinke-music-player-main .react-jinke-music-player-mode-icon {
  animation: scaleTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main .music-player-panel {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  box-shadow: 0 0 3px #403f3f;
  color: #fff;
  height: 80px;
  left: 0;
  position: fixed;
  transition: background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 100%;
  z-index: 99;
}
.react-jinke-music-player-main .music-player-panel .panel-content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  padding: 0 30px;
  position: relative;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-rail {
  background-color: hsla(0, 0%, 100%, 0.6);
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-handle,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-track {
  background-color: #31c27c;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-handle {
  border: 2px solid #fff;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-handle:active {
  box-shadow: 0 0 2px #31c27c;
}
.react-jinke-music-player-main .music-player-panel .panel-content .img-content {
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 34, 77, 0.05);
  cursor: pointer;
  height: 50px;
  overflow: hidden;
  width: 50px;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .img-content {
    height: 40px;
    width: 40px;
  }
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .img-content
  img {
  width: 100%;
}
.react-jinke-music-player-main .music-player-panel .panel-content .img-rotate {
  animation: imgRotate 15s linear infinite;
}
.react-jinke-music-player-main .music-player-panel .panel-content .hide-panel,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .upload-music {
  cursor: pointer;
  flex-basis: 10%;
  margin-left: 15px;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .progress-bar-content {
    display: none !important;
  }
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content {
  flex: 1;
  overflow: hidden;
  padding: 0 20px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-title {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  white-space: nowrap;
  width: 100%;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main {
  display: inline-flex;
  justify-content: center;
  margin-top: 6px;
  width: 100%;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main
  .current-time,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main
  .duration {
  flex-basis: 5%;
  font-size: 12px;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar {
  flex: 1 1 auto;
  margin: 2px 20px 0;
  position: relative;
  width: 100%;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .progress {
  background: linear-gradient(
    135deg,
    transparent,
    transparent 31%,
    rgba(0, 0, 0, 0.05) 33%,
    rgba(0, 0, 0, 0.05) 67%,
    transparent 69%
  );
  background-color: #31c27c;
  display: inline-block;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  transition: width 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .progress
  .progress-change {
  background-color: #fff;
  border-radius: 50%;
  bottom: -2px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  height: 10px;
  position: absolute;
  right: 0;
  width: 10px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .progress-load-bar {
  background-color: rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  height: 4px;
  left: 0;
  position: absolute;
  top: 5px;
  transition: width, background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 0;
  z-index: 77;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .rc-slider-track {
  z-index: 78;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .rc-slider-handle {
  z-index: 79;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content {
  align-content: center;
  align-items: center;
  display: inline-flex;
  flex-basis: 15%;
  padding-left: 5%;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group {
  align-items: center;
  display: inline-flex;
  flex: 1;
  justify-content: center;
  margin: 0 10px;
  text-align: center;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group
  > svg {
  cursor: pointer;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group
  .group {
  display: flex;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .player-content
    > .group {
    margin: 0 6px;
  }
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .player-content
    > .group {
    margin: 0 4px;
  }
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group
  > i {
  color: #31c27c;
  cursor: pointer;
  font-size: 25px;
  vertical-align: middle;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .theme-switch
  .rc-switch {
  background-color: transparent;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .player-content
    .play-sounds {
    display: none !important;
  }
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .next-audio
  svg,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .prev-audio
  svg {
  font-size: 35px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .loading-icon,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-btn {
  padding: 0 18px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-btn
  svg {
  font-size: 26px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .loop-btn.active {
  color: #31c27c;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds {
  align-items: center;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds
  svg {
  font-size: 28px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds
  .sounds-icon {
  display: flex;
  flex: 1 1 auto;
  margin-right: 15px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds
  .sound-operation {
  width: 100px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .destroy-btn
  svg {
  font-size: 28px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 40px;
  box-shadow: 0 0 1px 1px hsla(0, 0%, 100%, 0.02);
  height: 28px;
  min-width: 60px;
  padding: 0 10px;
  position: relative;
  transition: color, background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  user-select: none;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn
  .audio-lists-icon {
  display: flex;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn
  > .group:hover,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn
  > .group:hover
  > svg {
  color: #31c27c;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn
  .audio-lists-num {
  margin-left: 8px;
}
.react-jinke-music-player-main .music-player-panel .rc-switch-inner svg {
  font-size: 13px;
}
.react-jinke-music-player-main .rc-slider-rail {
  background-color: #fff !important;
  transition: background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main.light-theme svg {
  color: #31c27c;
}
.react-jinke-music-player-main.light-theme svg:active,
.react-jinke-music-player-main.light-theme svg:hover {
  color: #3ece89;
}
.react-jinke-music-player-main.light-theme .rc-slider-rail {
  background-color: rgba(0, 0, 0, 0.09) !important;
}
.react-jinke-music-player-main.light-theme .music-player-controller {
  background-color: #fff;
  border-color: #fff;
}
.react-jinke-music-player-main.light-theme .music-player-panel {
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 34, 77, 0.05);
  color: #7d7d7d;
}
.react-jinke-music-player-main.light-theme .music-player-panel .img-content {
  box-shadow: 0 0 10px #dcdcdc;
}
.react-jinke-music-player-main.light-theme
  .music-player-panel
  .progress-load-bar {
  background-color: rgba(0, 0, 0, 0.06) !important;
}
.react-jinke-music-player-main.light-theme .rc-switch {
  color: #fff;
}
.react-jinke-music-player-main.light-theme .rc-switch:after {
  background-color: #fff;
}
.react-jinke-music-player-main.light-theme .rc-switch-checked {
  background-color: #31c27c !important;
  border: 1px solid #31c27c;
}
.react-jinke-music-player-main.light-theme .rc-switch-inner {
  color: #fff;
}
.react-jinke-music-player-main.light-theme .audio-lists-btn {
  background-color: #f7f8fa !important;
}
.react-jinke-music-player-main.light-theme .audio-lists-btn:active,
.react-jinke-music-player-main.light-theme .audio-lists-btn:hover {
  background-color: #fdfdfe;
  color: #444;
}
.react-jinke-music-player-main.light-theme .audio-lists-btn > .group:hover,
.react-jinke-music-player-main.light-theme
  .audio-lists-btn
  > .group:hover
  > svg {
  color: #444;
}
.react-jinke-music-player-main.light-theme .audio-lists-panel {
  background-color: #fff;
  box-shadow: 0 0 2px #dcdcdc;
  color: #444;
}
.react-jinke-music-player-main.light-theme .audio-lists-panel .audio-item {
  background-color: #fff;
}
.react-jinke-music-player-main.light-theme
  .audio-lists-panel
  .audio-item:nth-child(odd) {
  background-color: #fafafa !important;
}
.react-jinke-music-player-main.light-theme
  .audio-lists-panel
  .audio-item.playing {
  background-color: #f2f2f2 !important;
}
.react-jinke-music-player-main.light-theme
  .audio-lists-panel
  .audio-item.playing,
.react-jinke-music-player-main.light-theme
  .audio-lists-panel
  .audio-item.playing
  svg {
  color: #31c27c !important;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main.light-theme .audio-lists-panel .audio-item {
    background-color: #fff !important;
  }
  .react-jinke-music-player-main.light-theme
    .audio-lists-panel
    .audio-item.playing {
    background-color: #f2f2f2 !important;
  }
  .react-jinke-music-player-main.light-theme
    .audio-lists-panel
    .audio-item.playing,
  .react-jinke-music-player-main.light-theme
    .audio-lists-panel
    .audio-item.playing
    svg {
    color: #31c27c !important;
  }
}
.react-jinke-music-player-main.light-theme .audio-lists-panel-header {
  background-color: #fff;
  border-bottom: 1px solid #f4f4f7;
  color: #444;
}
.react-jinke-music-player-main.light-theme .audio-lists-panel-header-line {
  background-color: #f4f4f7;
}
.react-jinke-music-player-main.light-theme .audio-item {
  background-color: rgba(64, 68, 75, 0.65);
  border-bottom: 1px solid hsla(0, 0%, 86.3%, 0.26);
  box-shadow: 0 0 2px transparent !important;
}
.react-jinke-music-player-main.light-theme .audio-item:active,
.react-jinke-music-player-main.light-theme .audio-item:hover {
  background-color: #fafafa !important;
}
.react-jinke-music-player-main.light-theme .audio-item:active svg,
.react-jinke-music-player-main.light-theme .audio-item:hover svg {
  color: #31c27c;
}
.react-jinke-music-player-main.light-theme .audio-item.playing {
  background-color: #fafafa !important;
}
.react-jinke-music-player-main.light-theme .audio-item.playing svg {
  color: #31c27c;
}
.react-jinke-music-player-main.light-theme .audio-item.playing .player-singer {
  color: #31c27c !important;
}
.react-jinke-music-player-main.light-theme .audio-item .player-singer {
  color: hsla(0, 0%, 63.5%, 0.45) !important;
}
.react-jinke-music-player-main.light-theme .react-jinke-music-player-mobile {
  background-color: #fff;
  color: #444;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-cover {
  border: 5px solid transparent;
  box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.2);
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile
  .current-time,
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile
  .duration {
  color: #444;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile
  .rc-slider-rail {
  background-color: #e9e9e9;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-operation
  svg {
  color: #444;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-tip
  svg {
  color: #fff !important;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-singer-name {
  color: #444;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-singer-name:after,
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-singer-name:before {
  background-color: #444;
}
.react-jinke-music-player-main.light-theme .play-mode-title {
  background-color: #fff;
  color: #31c27c;
}
.react-jinke-music-player {
  height: 80px;
  position: fixed;
  width: 80px;
  z-index: 999;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player {
    height: 60px;
    width: 60px;
  }
}
.react-jinke-music-player:focus {
  outline: none;
}
.react-jinke-music-player .music-player {
  cursor: pointer;
  height: 80px;
  position: relative;
  width: 80px;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player .music-player {
    height: 60px;
    width: 60px;
  }
}
.react-jinke-music-player .music-player:focus {
  outline: none;
}
.react-jinke-music-player .music-player-audio {
  display: none !important;
}
.react-jinke-music-player .music-player .destroy-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player .music-player .destroy-btn {
    right: -3px;
  }
}
.react-jinke-music-player .music-player-controller {
  align-items: center;
  background-color: #e6e6e6;
  background-repeat: no-repeat;
  background-size: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  color: #31c27c;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  height: 80px;
  justify-content: center;
  padding: 10px;
  position: fixed;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 80px;
  z-index: 99;
}
.react-jinke-music-player .music-player-controller:focus {
  outline: none;
}
.react-jinke-music-player .music-player-controller.music-player-playing:before {
  animation: scale 5s linear infinite;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  border-radius: 50%;
  content: "";
  cursor: pointer;
  height: 80px;
  position: fixed;
  width: 80px;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player .music-player-controller {
    height: 60px;
    width: 60px;
  }
  .react-jinke-music-player
    .music-player-controller.music-player-playing:before {
    height: 60px;
    width: 60px;
  }
}
.react-jinke-music-player .music-player-controller i {
  font-size: 28px;
}
.react-jinke-music-player .music-player-controller:active {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}
.react-jinke-music-player .music-player-controller:hover {
  font-size: 16px;
}
.react-jinke-music-player
  .music-player-controller:hover
  .music-player-controller-setting {
  transform: scale(1);
}
.react-jinke-music-player .music-player-controller .controller-title {
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player .music-player-controller i {
    font-size: 20px;
  }
  .react-jinke-music-player .music-player-controller:active,
  .react-jinke-music-player .music-player-controller:hover {
    font-size: 12px;
  }
  .react-jinke-music-player
    .music-player-controller:active
    .music-player-controller-setting,
  .react-jinke-music-player
    .music-player-controller:hover
    .music-player-controller-setting {
    transform: scale(1);
  }
}
.react-jinke-music-player
  .music-player-controller
  .music-player-controller-setting {
  align-items: center;
  background: rgba(49, 194, 124, 0.3);
  border-radius: 50%;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(0);
  transition: all 0.4s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 100%;
}
.react-jinke-music-player .audio-circle-process-bar {
  stroke-width: 3px;
  stroke-linejoin: round;
  animation: scaleTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  height: 80px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: -80px;
  width: 80px;
  z-index: 100;
}
.react-jinke-music-player .audio-circle-process-bar circle[class="bg"] {
  stroke: #fff;
}
.react-jinke-music-player .audio-circle-process-bar circle[class="stroke"] {
  stroke: #31c27c;
}
.react-jinke-music-player .audio-circle-process-bar,
.react-jinke-music-player .audio-circle-process-bar circle {
  transform: matrix(0, -1, 1, 0, 0, 80);
  transition: stroke-dasharray 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player .audio-circle-process-bar,
  .react-jinke-music-player .audio-circle-process-bar circle {
    transform: matrix(0, -1, 1, 0, 0, 60);
  }
}
