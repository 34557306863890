/* General CSS Settings Start */
@font-face {
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/AvenirNextLTPro-Regular.b5ed9330.otf) format("otf");
  color: #313a40;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  font-family: "Avenir Next" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-success {
  background-color: #4ca746 !important;
  color: #fff;
}

.bg-haze {
  background-color: #fffaf0 !important;
}

.bg-loading {
  background-color: #f9e6c7 !important;
}

.bg-red {
  background-color: #cf3c3f !important;
  color: #fff;
}

.bg-blue {
  background-color: #26466d;
}

.bg-dark-grey {
  background-color: #3e4755 !important;
  color: #fff;
}

.bg-grey {
  background-color: #fafafa !important;
}
.bg-lt-grey {
  background-color: #dedede !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-none {
  background: transparent !important;
}

.bg-dark-blue {
  background-color: #26466d !important;
  color: #fffaf0;
}

.font-black {
  color: #000 !important;
}

.font-grey {
  color: #bababa !important;
}

.font-dark-grey {
  color: #313a40 !important;
}

.font-green {
  color: #81cac4 !important;
}

.font-red {
  color: #ff0000 !important;
}

.font-white {
  color: #fff !important;
}

.fw-bold {
  font-weight: 600;
}
.fa-sm {
  font-size: 0.75em !important;
}

.fa-1-5x {
  font-size: 1.5em;
}
/* General CSS Settings End */

/* Pre Login Home Carousel CSS Settings start */
.carousel-item img {
  padding: 1px;
  max-height: 600px;
  display: block !important;
}

.carousel-item .carousel-caption {
  bottom: 40%;
}

.carousel-caption h3 {
  font-size: 3rem;
  color: #000;
}

.carousel-caption p {
  font-size: 2rem;
  color: #000;
  font-weight: 500;
}

/* Carousel CSS Settings end */

/* Profile CSS Settings Start */
.profile-banner {
  height: 300px;
  background-color: #cecece;
}

.profile-background-image {
  max-width: 100%;
  max-height: 100%;
}

.profile-banner .profile-banner-wrapper {
  overflow: hidden;
  height: 100%;
}

.profile-banner .profile-banner-wrapper img {
  -webkit-transform: scale(3);
  transform: scale(3);
}

.profile-banner-2 {
  height: 50px;
  background-color: #2f3a41;
}
.main-left {
  height: 640px;
  background-color: #ffffff;
}

.profile-img .profile-img-wrapper {
  overflow: hidden;
}

.profile-img .profile-img-wrapper img {
  /*-webkit-transform: scale(1.5);*/
  /*-moz-transform: scale(1.5);*/
  /*-ms-transform: scale(1.5);*/
  /*-o-transform: scale(1.5);*/
  /*transform: scale(1.5);*/
}

.fav-container {
  height: 640px;
  background-color: #ffffff;
  overflow-y: scroll;
}

.fav-heading {
  line-height: 1.5rem;
}
.fav-content .card-body {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
}

.fav-content .fav-title {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.profile-img {
  height: 200px;
  width: 200px;
  margin: 0 auto;
  /*position: relative;*/
  top: -135px;
  /*border: 1px solid #a9a9a9;*/

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  position: absolute;
}

.profile-img-inner {
  margin: 3px;
  padding: 2px;
  background-color: #cecece;
}

.profile-img img {
  width: 100%;
  border-radius: 3%;
}

.profile-summary {
  position: relative;
  /*top: -180px;*/
  padding: 5rem 2rem 3rem 2rem;
}

.profile-summary h5 {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 3.75px;
  line-height: 34px;
}
.profile-summary p {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.61px;
  line-height: 20px;
}

.recitation-list .rec-body-item {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.92px;
  line-height: 15px;
}

.recitation-list-body h6 {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.08px;
  line-height: 18px;
}

.recent-uploads-wrapper .poem-image,
.profile-details .poem-image {
  max-width: 50px;
  max-height: 50px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

/* Profile CSS Settings End */

/* FAQ CSS Settings Start */
.bg-scroll {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  position: absolute;
  z-index: -1;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 350px;
  opacity: 0.25;
}

.bg-front {
  height: 350px;
}

.App .navbar .faq-search {
  flex-grow: 2;
  max-width: 400px;
}

.list-group-item .faq-answer {
  border: none;
  border-radius: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
/* FAQ CSS Settings End */

/* POST LOGIN HOME PAGE CSS START */

.alice-carousel__stage-item {
  padding: 0 20px;
}

.alice-carousel .alice-carousel__stage {
  margin: 0 -20px !important;
}

.default-recordings-item {
  width: 150px;
  height: 215px;
  border: 1px solid f9e6c7;
}

.featured-recordings-item .card-footer {
  background-color: transparent;
  border: 0;
}

.featured-recordings-item:hover {
}

.featured-recordings-item img,
.default-recording-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.featured-recordings-item h5 {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 1.08px;
}
.featured-recordings-item p {
  font-size: 0.7em;
}

/* Profile Playlist Carousel */

.playlist-item .card-footer {
  background-color: transparent;
  border: 0;
}
.playlist-item img {
  /* width: 148px;  */
  border-radius: 4%;
  width: 100%;
}

.playlist-item h5 {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 1.08px;
}
.playlists-item p {
  font-size: 0.7em;
}

/* POST LOGIN HOME PAGE CSS END */

.card {
  box-shadow: 2px -3px 3px 0 rgba(0, 0, 0, 0.1);
}

/* Media Player CSS Start */
.index_av__background__1Lhbu {
  position: -webkit-sticky !important;
  position: sticky !important;
  width: 100% !important;
}

/* Media Player CSS End*/

/* Carousel */

.carousel-container .carousel-container-arrow {
  /*float: right;*/
}

/* Recitation List */

.recitation-list
  .recitation-list-body
  .recitation-list-body-like
  .recitation-list-body-like-button {
  cursor: pointer;
}

/* Comment */
.comment-list {
  text-align: left;
}

.navbar-brand {
  color: #fffaf0 !important;
  font-family: "Avenir Next" !important;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 1.4px;
  line-height: 19px;
}

/**
  Global Style
**/
html,
body {
  background-color: #fff; }

a:hover {
  text-decoration: none; }

img {
  max-width: 100%; }

.App {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;
  overflow-x: auto;
  word-wrap: break-word;
  height: 1;
  /* Set the height of the App to 100% of the viewport height */
  width: 100vw; }

@media (max-width: 768px) {
  .App {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex-flow: column;
    word-wrap: break-word; } }

.poem-script {
  margin: 20px 0;
  white-space: pre-line; }
  .poem-script .ql-editor {
    padding: 0px; }
  .poem-script .ql-container {
    border: none !important; }
  .poem-script p {
    font-size: medium; }

.music-player .music-player-panel {
  padding-left: 80px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important;
  z-index: 1000;
  left: 0;
  bottom: 0; }

.music-player .music-player-lyric {
  padding: 10px;
  cursor: pointer !important;
  bottom: 80px !important;
  background-color: #223e61 !important; }

.music-player .audio-lists-panel {
  right: 0; }

.music-player .react-jinke-music-player {
  z-index: 1000;
  top: auto !important;
  left: auto !important;
  right: 0 !important;
  bottom: 0 !important; }
  .music-player .react-jinke-music-player .music-player-controller {
    width: 60px;
    height: 60px; }
    .music-player .react-jinke-music-player .music-player-controller .music-player-controller-setting {
      font-size: 14px;
      background: rgba(49, 194, 124, 0.8); }

/**
  Custom Style
**/
.interior-page h2,
.interior-page h3,
.interior-page h4,
.interior-page h5,
.interior-page ul,
.interior-page ol,
.interior-page p {
  margin-bottom: 20px; }

.interior-page h6 {
  color: #26466d;
  font-size: 1.15em;
  margin-bottom: 10px; }

.center {
  text-align: center; }

.page-main {
  display: flex;
  flex-wrap: wrap; }

.page-wrapper {
  margin: 66px 0 80px 80px;
  padding: 40px 0; }

.lyrics-text {
  font-family: "Avenir Next"; }

.lyrics-text-title {
  font-size: xx-large; }

.page-title {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 1.6em;
  font-weight: 600;
  text-align: center;
  letter-spacing: 2.14px;
  line-height: 27px;
  margin-bottom: 40px; }

.page-title-sm {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 1.3em;
  font-weight: 600;
  text-align: center;
  letter-spacing: 2.14px;
  line-height: 27px;
  margin-bottom: 40px; }

.section-title {
  color: #26466d;
  font-weight: 500;
  font-size: 1.2em;
  letter-spacing: 2.14px; }

.space {
  margin-top: 60px;
  margin-bottom: 60px; }

.space-md {
  margin-top: 40px;
  margin-bottom: 40px; }

.space-sm {
  margin-top: 20px;
  margin-bottom: 20px; }

.top-space {
  margin-top: 60px; }

.top-space-md {
  margin-top: 40px; }

.top-space-sm {
  margin-top: 20px; }

.left-space {
  margin-left: 40px; }

.left-space-md {
  margin-left: 20px; }

.left-space-sm {
  margin-left: 10px; }

.right-space {
  margin-right: 40px; }

.right-space-md {
  margin-right: 20px; }

.right-space-sm {
  margin-right: 10px; }

.flex {
  display: flex;
  align-items: center; }

.wrap {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px; }

.button {
  display: inline-block;
  height: 40px;
  padding: 0 25px;
  border-radius: 5px;
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.1px;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  border: 1px;
  transition: 0.2s;
  background-color: #ddd; }
  .button.small {
    height: 34px;
    line-height: 34px;
    font-size: 11px; }
  .button.primary {
    background-color: #26466d;
    color: #fff; }
    .button.primary:hover {
      background-color: #223e61; }
  .button:hover {
    text-decoration: none;
    cursor: pointer;
    background-color: #ccc; }
  .button.danger {
    background-color: #e74c3c;
    color: #fff; }
    .button.danger:hover {
      background-color: #c0392b; }
  .button.warning {
    background-color: #f39c12;
    color: #fff; }
    .button.warning:hover {
      background-color: #d35400; }

button.edit-button {
  background: none;
  padding: 0;
  border: 0;
  color: #282c34; }
  button.edit-button::after {
    display: none; }

.featured-poet-image {
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: #cdcdcd; }

.recent-uploads-wrapper,
.featured-poet-wrapper {
  height: 540px;
  overflow-y: scroll; }

.content-list {
  width: 100%;
  padding: 0;
  margin-bottom: 0;
  list-style: none; }

.content-list-item {
  display: flex;
  align-items: center; }
  .content-list-item .item-image {
    position: relative;
    width: 80px;
    height: 80px;
    background-color: #c1c1c1; }
    .content-list-item .item-image img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .content-list-item .item-info {
    flex: 1 1;
    margin-left: 20px; }
    .content-list-item .item-info p {
      margin-top: 5px;
      margin-bottom: 0; }

.reading-list-item + .reading-list-item {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #fff; }

.item-cta {
  display: flex; }
  .item-cta button {
    margin-left: 20px;
    font-size: 18px;
    margin-right: 0;
    padding: 0;
    border: 0 !important;
    background: none !important; }
    .item-cta button svg {
      margin: 0 !important;
      color: #223e61; }
    .item-cta button:hover svg {
      color: #26466d; }

.group-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.group-list-item {
  padding: 10px;
  background-color: #fff; }
  .group-list-item a {
    color: #26466d; }
    .group-list-item a:hover {
      color: #223e61; }
  .group-list-item .item-image {
    background-color: #c1c1c1; }

.group-manage-modal img {
  width: 100%; }

.group-manage-modal .main-photo {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden; }
  .group-manage-modal .main-photo img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.group-manage-modal .member-field {
  display: flex; }
  .group-manage-modal .member-field .button {
    height: 38px;
    line-height: 38px; }

.member-list-item + .member-list-item {
  margin-top: 20px;
  padding-top: 20px; }

.playlist-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.playlist-list-item {
  width: 49%; }
  .playlist-list-item:nth-child(n + 4) {
    margin-top: 20px;
    padding-top: 20px; }

.tab-container {
  margin-top: 40px;
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 20px; }

.craousel-control {
  cursor: pointer; }

.tab-control {
  position: relative;
  margin-top: 40px; }
  .tab-control .nav-tabs .nav-item a {
    cursor: pointer; }
  .tab-control .button {
    position: relative;
    background-color: #223e61;
    color: white; }

.comments {
  margin-top: 80px;
  padding-top: 40px;
  border-top: 1px solid #ccc; }
  .comments h4 {
    margin-bottom: 20px; }
  .comments .comment-form {
    overflow: hidden;
    margin-bottom: 20px; }
    .comments .comment-form .comment-fields {
      display: flex;
      flex-wrap: nowrap; }
    .comments .comment-form .button {
      float: right;
      margin-top: 10px; }
  .comments .comment-list {
    padding: 0;
    list-style: none;
    overflow: auto; }
    .comments .comment-list li {
      list-style: none; }
      .comments .comment-list li > div {
        display: table-cell; }

.commenter-image {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  float: left; }
  .commenter-image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; }

.commenter-image-abs {
  position: absolute; }

.comment-text-bold {
  font-weight: 500; }

.comment-text p {
  margin: 0; }

.sub-text {
  font-size: 11px; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.login-wrapper {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-wrapper .wrapper-card {
  width: 308px;
  min-width: 308px;
  background: none;
  color: #fff;
}

.login-wrapper .card-img {
  width: auto;
  margin: 0 auto;
  cursor: pointer;
}

.login-wrapper input,
.login-wrapper input:focus,
.login-wrapper input:active {
  outline: 0;
  box-shadow: none;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #fff;
  background: none;
  color: #fff;
  font-weight: 500;
  padding: 0;
  height: calc(1.5rem + 2px);
}

.login-wrapper .form-control.is-valid:focus {
  box-shadow: none;
}

.login-wrapper .nav-tabs .nav-item.show .nav-link,
.login-wrapper .nav-tabs .nav-link,
.login-wrapper .nav-tabs .nav-link.active {
  color: #313a40;
  background: none;
  border: none;
  border-bottom: 2px solid black;
  padding: 0.2em 0;
  margin: 0 0.8em;
}

.login-wrapper .nav-tabs .nav-link {
  border: none;
}

.form-icon {
  position: relative;
  top: 2.8rem;
}
.login-page .form-label {
  color: #313a40;
  text-transform: uppercase;
  height: 19px;
  width: 48px;
  color: #313a40;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.46px;
  line-height: 19px;
  font-size: 0.8rem;
}

.invalid-tooltip,
.valid-tooltip {
  position: relative;
}

.invalid-tooltip.show,
.valid-tooltip.show {
  display: block;
}

.login-page .submit-button {
  height: 29px;
  width: 100px !important;
  background-color: #313a40 !important;
  color: #fffaf0 !important;
  color: #fffaf0;
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.8px;
  line-height: 16px;
  text-align: center;
}

.login-page .poetry-maintext {
  color: #fffaf0;
  font-family: "Avenir Next";
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 1.83px;
  line-height: 30px;
  text-align: center;
}

.login-page .login-navbar {
  background-color: #26466d;
  color: #fffaf0;
}
.login-page .App .navbar-light.navbar .nav-item.dropdown .nav-link {
  color: #fffaf0;
}

.login-page .navbar-light .navbar-brand {
  color: #fffaf0;
  font-family: "Avenir Next";
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 1.7px;
}

.login-page .App .navbar {
  padding: 10px !important;
}

.login-page .create-account-text {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 27px;
  text-align: left;
}

.login-page .create-account-subtext {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 20px;
  text-align: left;
}

.login-page .login-button {
  border-radius: 2px;
  background-color: #fffaf0;
  color: #26466d;
  font-family: "Avenir Next";
  font-weight: 600;
  letter-spacing: 0.59px;
}

.login-page .login-button:hover {
  color: #fffaf0;
  background-color: #26466d;
}

.App .navbar .nav-item:hover {
  background-color: #26466d !important;
}
.login-page .signup-button {
  border-radius: 2px !important;
  color: #fffaf0;
  font-family: "Avenir Next";
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 14px;
  text-align: center;
  background-color: #26466d !important;
  margin-top: 2rem;
}

.login-page input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #26466d !important;
}
.login-page input:focus {
  border-color: green !important;
}

.login-page .half-width {
  width: 50%;
}

.other-options {
  color: #26466d;
  font-family: "Avenir Next";
  font-weight: 600;
  letter-spacing: 1.3px;
  line-height: 18px;
  text-align: left;
}

.small-text {
  font-size: 12px;
}

.login-wrapper input,
.login-wrapper input:focus,
.login-wrapper input:active {
  color: black;
}

.login-navbar {
  padding-top: 0.5em;
}
.register-form .form-group {
  margin-bottom: 2rem;
}

.forget-password {
  color: #00ff33;
  font-size: 12px;
}

.sidebar {
  position: fixed;
  height: 100vh;
  top: 80px;
  left: 0;
  background: #2f3a41;
  overflow-y: auto;
}

.sidebar.open {
  width: 250px;
}

.sidebar.closed {
  width: 80px;
}

.sidebar-menu,
.sidebar-menu li {
  padding: 0;
  list-style: none;
}

.sidebar-link {
  display: block;
  padding: 0.8rem 2rem;
  color: #fff;
  text-decoration: none;
  background-color: #2f3a41;
  text-align: left;
}

.sidebar-link:hover {
  text-decoration: none;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-link .icon {
  margin-right: 0.5rem;
}

.sidebar.closed .sidebar-link {
  padding: 0.6rem 0.3rem;
  text-align: center;
}

.sidebar.closed .sidebar-link span {
  display: block;
  font-size: 0.7em;
}

.sidebar.closed .sidebar-link .icon {
  margin-right: 0;
}

.sidebar-divider {
  height: 1px;
  margin: 1rem;
  background-color: #757575;
}

.sidebar-content {
  color: #fff;
  background: #2f3a41;
}

.faq-group + .faq-group {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.faq-group h6 {
  margin-top: 25px;
}
.pdf-button {
  position: relative;
  margin: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  padding: 10px;
  background-color: rgba(34, 62, 96, 1);
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.pdf-button__text {
  font-size: 16px;
  font-weight: bold;
  padding: 0.5rem;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
}
.pdf-button__subtitle {
  font-size: 16px;
  padding: 0;
  margin-top: 10px;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
}

.pdf-button:hover .pdf-button__text {
  opacity: 0.6;
}
.pdf-button:hover .pdf-button__subtitle {
  opacity: 0;
}

.pdf-button__overlay {
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(34, 62, 96, 1);
  display: flex;
  margin-bottom: 3rem;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.pdf-button__overlay--visible {
  opacity: 1;
}

.pdf-button__overlay-text {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  max-width: calc(100vw - 3rem);
}

@media (min-width: 576px) {
  .pdf-button {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .pdf-button:last-child {
    margin-right: 0;
  }
}

.custom-modal-dialog {
  max-width: 80%;
  max-height: 90%;
}

.App .navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 1rem;
  color: #fff;
  z-index: 1000; }
  .App .navbar .navbar-nav {
    display: flex;
    align-items: center; }

.App .navbar-light .navbar-nav .nav-item .nav-link {
  color: #fff; }

.App .navbar-light.navbar .nav-item .nav-link:hover {
  color: #000; }

.App .navbar-light.navbar .nav-item.dropdown .nav-link {
  color: #000; }

.App .navbar-light.navbar .nav-item.dropdown .dropdown-item {
  padding: 8px 20px; }

.App .navbar .nav-item {
  margin: 0 5px; }

.App .navbar .nav-item:hover {
  background-color: #fffaf0;
  border-radius: 5px 5px 0 0;
  transition: all 0.5s ease; }

.App .underline:after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease; }

.App .navbar .underline:hover:after {
  width: 100%;
  background: #000; }

.App .navbar-brand .logo {
  height: 56px; }

.App .navbar {
  height: 80px; }
  .App .navbar .logo {
    display: flex;
    align-items: center; }
    .App .navbar .logo span {
      font-family: "Avenir Next";
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1.4px;
      line-height: 1; }
    .App .navbar .logo img {
      font-size: 0;
      height: 44px;
      margin-right: 15px; }

.App .create-btn a {
  color: #000; }

.App .navbar .nav-item.profile {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 0 auto; }

.App .nav-profile {
  width: 40px;
  height: 40px;
  position: relative;
  top: 2.75px;
  left: -2px;
  border-radius: 50%; }

.App .navbar .search {
  flex-grow: 2;
  max-width: 400px; }

.App .navbar .svg-inline--fa {
  font-size: 1.1rem; }

.rbt-input-hint-container:focus {
  outline: none; }

input.rbt-input-main.form-control.rbt-input,
input.rbt-input-main.form-control.rbt-input:focus,
input.rbt-input-main.form-control.rbt-input:active {
  background-color: transparent;
  color: #fff;
  border: 0;
  border-bottom: 1.5px solid white;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  /* visibility: hidden;
  opacity: 0;
  width:0;  */ }

input.rbt-input-main.form-control.rbt-input.show,
input.rbt-input-main.form-control.rbt-input.show:focus,
input.rbt-input-main.form-control.rbt-input.show:active {
  visibility: visible;
  opacity: 1;
  width: 100%; }

input.rbt-input-main.form-control.rbt-input::-webkit-input-placeholder {
  color: #fff;
  font-weight: 500; }

input.rbt-input-main.form-control.rbt-input::placeholder {
  color: #fff;
  font-weight: 500; }

.new-recording {
  border: 1px solid white;
  font-size: 0.9rem;
  position: relative;
  top: 4px; }

.login-button {
  border-radius: 2px;
  background-color: #fffaf0;
  color: #26466d;
  font-family: "Avenir Next";
  font-weight: 600;
  letter-spacing: 0.59px; }

.login-button:hover {
  text-decoration: none; }

.header--text-input {
  background-color: white !important;
  padding: 15px !important;
  border-radius: 10px !important; }

.create-btn .dropdown-toggle {
  background-color: #31C27D !important;
  border: 0; }

.homepage {
  margin-left: 100px;
  margin-right: 20px; }

.content-section + .content-section {
  margin-top: 40px; }

.main-content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px; }

.content-main {
  width: 62%; }

.content-sub {
  width: 35%;
  margin-left: 3%; }

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; }

.content-section {
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 5px; }

.featured-content .card {
  border: 0; }

.featured-content .featured-recording-img {
  background-color: #c1c1c1; }

.playlist-list {
  justify-content: start; }

.popular-playlists h5 {
  padding: 0; }

.popular-playlists a {
  display: block;
  color: #26466d; }
  .popular-playlists a:hover {
    color: #223e61; }

.popular-playlists .playlist-images {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 100%;
  list-style: none;
  font-size: 0;
  background-color: #fff; }
  .popular-playlists .playlist-images .playlist-image {
    position: absolute;
    width: calc(50% - 3px);
    height: calc(50% - 3px);
    background-color: #c1c1c1;
    border-radius: 4px;
    overflow: hidden; }
    .popular-playlists .playlist-images .playlist-image:nth-child(1) {
      top: 2px;
      left: 2px; }
    .popular-playlists .playlist-images .playlist-image:nth-child(2) {
      top: 2px;
      right: 2px; }
    .popular-playlists .playlist-images .playlist-image:nth-child(3) {
      bottom: 2px;
      left: 2px; }
    .popular-playlists .playlist-images .playlist-image:nth-child(4) {
      bottom: 2px;
      right: 2px; }
    .popular-playlists .playlist-images .playlist-image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.alice-carousel__dots {
  margin-top: 15px; }

.recent-upload .upload-list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1em; }
  .recent-upload .upload-list .list-item h5 {
    font-size: 1.1em; }
    .recent-upload .upload-list .list-item h5 a {
      color: #26466d; }
      .recent-upload .upload-list .list-item h5 a:hover {
        color: #223e61; }
    .recent-upload .upload-list .list-item h5 p {
      font-size: 11px; }
      .recent-upload .upload-list .list-item h5 p b {
        color: #26466d; }
      .recent-upload .upload-list .list-item h5 p a {
        font-size: 11px;
        font-weight: 400;
        color: #333; }
        .recent-upload .upload-list .list-item h5 p a:hover {
          color: #26466d; }
  .recent-upload .upload-list .list-item .item-cta {
    font-size: 11px; }
  .recent-upload .upload-list .list-item + .list-item {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #fff; }

.featured-poet-heading {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 25px; }

.poet-name {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1.88px;
  line-height: 20px; }

.biography {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.29px;
  line-height: 16px; }

.featured-text {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 13px;
  letter-spacing: 1.08px;
  line-height: 18px; }

.featured-bar {
  background-color: #26466d; }

.white {
  color: white; }

.featured-head,
.featured-head:hover {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
  text-decoration: none; }

.featured-poet,
.featured-poet:hover {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px; }

.recently-uploaded {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2.57px;
  line-height: 25px; }

.content-section {
  position: relative; }

.profile-page.page-wrapper {
  margin-top: 0;
  padding-top: 0; }

.profile-banner-wrapper {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden; }

.profile-banner-wrapper img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  object-fit: cover;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.profile-header {
  display: flex;
  position: relative; }

.profile-image {
  position: relative;
  width: 200px;
  height: 200px;
  margin-top: -125px;
  overflow: hidden;
  border-radius: 50%; }
  .profile-image img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.profile-info {
  flex: 1 1;
  margin-top: 20px;
  margin-left: 20px; }

.profile-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #26466d;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px; }

.profile-following {
  display: flex;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  letter-spacing: 0.5px; }

.profile-following li + li {
  margin-left: 20px; }

.profile-following li span {
  display: inline-block;
  margin-right: 5px;
  font-weight: 500;
  color: #26466d; }

.profile-groups .group-list-item {
  width: 49%;
  min-height: 100px; }
  .profile-groups .group-list-item:nth-child(n + 3) {
    margin-top: 20px; }
  .profile-groups .group-list-item a {
    color: #26466d; }
    .profile-groups .group-list-item a:hover {
      color: #223e61; }

.profile-groups .add-group {
  position: relative;
  min-height: 50;
  cursor: pointer; }
  .profile-groups .add-group::before, .profile-groups .add-group::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #26466d; }
  .profile-groups .add-group::before {
    width: 2px;
    height: 25px; }
  .profile-groups .add-group::after {
    width: 25px;
    height: 2px; }

.group-form .member-field {
  display: flex; }

.playlist-list {
  justify-content: start; }
  .playlist-list .playlist-list-item {
    width: 32%;
    display: block;
    margin-left: 2%;
    background-color: #fff;
    cursor: pointer; }
    .playlist-list .playlist-list-item:nth-child(3n + 1) {
      margin-left: 0; }
    .playlist-list .playlist-list-item .playlist-images {
      position: relative;
      width: 100%;
      margin-bottom: 0;
      padding-bottom: 100%;
      list-style: none;
      font-size: 0;
      border-bottom: solid 1px #efefef; }
      .playlist-list .playlist-list-item .playlist-images a {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%; }
      .playlist-list .playlist-list-item .playlist-images img {
        position: absolute;
        width: 50%;
        height: 50%;
        font-size: 0;
        -webkit-transform: translate(0);
                transform: translate(0); }
        .playlist-list .playlist-list-item .playlist-images img:nth-child(1) {
          top: 0;
          left: 0; }
        .playlist-list .playlist-list-item .playlist-images img:nth-child(2) {
          top: 0;
          left: 50%; }
        .playlist-list .playlist-list-item .playlist-images img:nth-child(3) {
          top: 50%;
          left: 0; }
        .playlist-list .playlist-list-item .playlist-images img:nth-child(4) {
          top: 50%;
          left: 50%; }
    .playlist-list .playlist-list-item .item-header {
      display: flex;
      align-items: center;
      padding: 10px; }
      .playlist-list .playlist-list-item .item-header .item-info {
        margin-left: 0; }
        .playlist-list .playlist-list-item .item-header .item-info h5 {
          margin: 0;
          padding: 0; }
          .playlist-list .playlist-list-item .item-header .item-info h5 a {
            color: #26466d; }
            .playlist-list .playlist-list-item .item-header .item-info h5 a:hover {
              color: #223e61; }
  .playlist-list .playlist-add {
    position: relative;
    min-height: 100px;
    cursor: pointer; }
    .playlist-list .playlist-add::before, .playlist-list .playlist-add::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      background-color: #26466d; }
    .playlist-list .playlist-add::before {
      width: 2px;
      height: 25px; }
    .playlist-list .playlist-add::after {
      width: 25px;
      height: 2px; }

.profile-manage-modal img {
  width: 100%; }

.profile-manage-modal .main-photo {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden; }
  .profile-manage-modal .main-photo img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.profile-manage-modal .member-field {
  display: flex; }
  .profile-manage-modal .member-field .button {
    height: 38px;
    line-height: 38px; }

.profile-manage-modal label {
  font-weight: bold; }

.recitation-edit-manage-modal .member-field {
  display: flex; }
  .recitation-edit-manage-modal .member-field .button {
    height: 38px;
    line-height: 38px; }

.recitation-edit-manage-modal label {
  font-weight: bold; }

@media (max-width: 767px) {
  /* Change the size for mobile devices */
  .profile-image {
    margin-top: -220px;
    position: absolute;
    width: 200px;
    /* Set width in percentage */
    height: 200px; }
  .profile-groups .group-list-item {
    width: 100%;
    min-height: 50px; }
    .profile-groups .group-list-item:nth-child(n + 2) {
      margin-top: 20px; }
    .profile-groups .group-list-item a {
      color: #26466d; }
      .profile-groups .group-list-item a:hover {
        color: #223e61; }
  .profile-groups .add-group {
    position: relative;
    min-height: 50;
    cursor: pointer; }
    .profile-groups .add-group::before, .profile-groups .add-group::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      background-color: #26466d; }
    .profile-groups .add-group::before {
      width: 2px;
      height: 25px; }
    .profile-groups .add-group::after {
      width: 25px;
      height: 2px; }
  .playlist-list {
    justify-content: start; }
    .playlist-list .playlist-list-item {
      width: 100%;
      display: block;
      margin-top: 5%;
      background-color: #fff;
      cursor: pointer; }
      .playlist-list .playlist-list-item .playlist-images {
        position: relative;
        width: 100%;
        margin-bottom: 0;
        padding-bottom: 100%;
        list-style: none;
        font-size: 0;
        border-bottom: solid 1px #efefef; }
        .playlist-list .playlist-list-item .playlist-images a {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%; }
        .playlist-list .playlist-list-item .playlist-images img {
          position: absolute;
          width: 50%;
          height: 50%;
          font-size: 0;
          -webkit-transform: translate(0);
                  transform: translate(0); }
          .playlist-list .playlist-list-item .playlist-images img:nth-child(1) {
            top: 0;
            left: 0; }
          .playlist-list .playlist-list-item .playlist-images img:nth-child(2) {
            top: 0;
            left: 50%; }
          .playlist-list .playlist-list-item .playlist-images img:nth-child(3) {
            top: 50%;
            left: 0; }
          .playlist-list .playlist-list-item .playlist-images img:nth-child(4) {
            top: 50%;
            left: 50%; }
      .playlist-list .playlist-list-item .item-header {
        display: flex;
        align-items: center;
        padding: 10px; }
        .playlist-list .playlist-list-item .item-header .item-info {
          margin-left: 0; }
          .playlist-list .playlist-list-item .item-header .item-info h5 {
            margin: 0;
            padding: 0; }
            .playlist-list .playlist-list-item .item-header .item-info h5 a {
              color: #26466d; }
              .playlist-list .playlist-list-item .item-header .item-info h5 a:hover {
                color: #223e61; }
    .playlist-list .playlist-add {
      position: relative;
      min-height: 100px;
      cursor: pointer; }
      .playlist-list .playlist-add::before, .playlist-list .playlist-add::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #26466d; }
      .playlist-list .playlist-add::before {
        width: 2px;
        height: 25px; }
      .playlist-list .playlist-add::after {
        width: 25px;
        height: 2px; } }

/* Poem Banner Style */
.poem-banner {
  position: relative;
  width: 100%;
  padding: 40px 0;
  overflow: hidden;
  z-index: 0;
  color: #fff; }

.poem-page.page-wrapper {
  margin-top: 0;
  padding-top: 0; }

.poem-banner-wrapper {
  position: relative;
  width: 100%;
  padding: 150px 0;
  overflow: hidden;
  z-index: 0;
  color: #fff;
  margin-bottom: 20px; }

.poem-banner-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }
  .poem-banner-background img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.poem-banner-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); }

.poem-banner-content {
  display: flex;
  align-items: center;
  font-size: 1.2em; }

.poem-banner-title {
  flex: auto;
  margin-left: 40px; }

.poem-banner-subtitle {
  font-size: 17px; }

.poem-banner-image {
  position: relative;
  width: 100px;
  /* Set width in percentage */
  height: 100px;
  border: 3px solid #fff;
  border-radius: 50%;
  overflow: hidden; }
  .poem-banner-image img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: inline;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

/* Add media queries for different screen sizes */
.poem-container {
  text-align: left; }

.poem-intro {
  display: flex;
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
  line-height: 1.2; }
  .poem-intro li + li {
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid #999; }

.poem-background {
  height: 230px;
  width: auto;
  object-fit: contain; }

.poem-title {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 27px; }

.poem-user {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 19px;
  text-align: center; }

.play-number {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1.67px;
  line-height: 27px;
  text-align: center; }

.total-plays {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center; }

.poem-author-profile-img {
  padding: 3px;
  width: 150px;
  height: 150px; }

.recitation-author-profile-img {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.poem-author-profile-img-inner {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.progress {
  height: 5px; }

.progress-bar {
  background-color: #000;
  height: 5px; }

.poem-text {
  height: 470px;
  overflow: scroll; }

.display-linebreak {
  white-space: pre-line; }

.poem-content {
  display: flex;
  position: relative; }

.poem-detail {
  flex: 2 1;
  margin-right: 40px; }

.poem-sidebar {
  flex: 1 1; }

.poem-script {
  margin: 20px 0;
  white-space: pre-line; }
  .poem-script .ql-editor {
    padding: 0; }
  .poem-script .ql-container {
    border: none !important; }
  .poem-script p {
    font-size: medium; }
  .poem-script h1 {
    font-size: xx-large; }

.poem-actions {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #212529;
  margin-bottom: 20px;
  background-color: #f8f9fa;
  padding: 10px; }
  .poem-actions button {
    display: flex;
    align-items: center;
    margin-right: 60px;
    padding: 0;
    border: 0;
    background: none;
    line-height: 1; }
  .poem-actions a {
    color: #212529; }
    .poem-actions a:hover {
      color: #223e61; }
  .poem-actions img,
  .poem-actions svg {
    display: inline-block;
    margin-right: 10px; }

.poem-actions-list li {
  padding: 0; }

.poem-actions-list .poem-action-btn {
  display: flex;
  width: 100%;
  align-items: center;
  background: none;
  border: 0;
  color: #212529;
  font-size: 16px;
  line-height: 1;
  margin: 0;
  padding: 15px 20px; }
  .poem-actions-list .poem-action-btn:hover {
    background-color: #f8f9fa; }
  .poem-actions-list .poem-action-btn img,
  .poem-actions-list .poem-action-btn i {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 15px;
    font-size: 22px;
    line-height: 0.7;
    color: #26466d; }
  .poem-actions-list .poem-action-btn p {
    margin-bottom: 0; }

.button-dark-blue {
  color: #26466d;
  background-color: #fffaf0; }

.play-button-poem {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 100% !important;
  background-color: #31c27c;
  border-radius: 50%;
  color: #fff;
  -webkit-transform: scale(2);
          transform: scale(2); }
  .play-button-poem:hover {
    background-color: #223e61; }

.poem-more-readings .section-title {
  margin-bottom: 20px; }

.poem-more-readings .item-info h5 {
  display: flex;
  justify-content: space-between;
  font-size: 1.1em;
  margin-bottom: 10px; }
  .poem-more-readings .item-info h5 a {
    color: #26466d; }
    .poem-more-readings .item-info h5 a:hover {
      color: #223e61; }

.poem-more-readings .item-info p {
  font-size: 11px; }
  .poem-more-readings .item-info p b {
    color: #26466d; }
  .poem-more-readings .item-info p a {
    font-weight: 400;
    color: #333; }
    .poem-more-readings .item-info p a:hover {
      color: #26466d; }

.poem-more-readings .item-cta {
  font-size: 11px; }

.rec-action-buttons {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.75px;
  line-height: 12px;
  text-align: center;
  padding-top: 5px; }

.hover-link {
  color: black !important; }

.hover-link:hover {
  color: blue !important; }

.no-border {
  border: none !important; }

@media (max-width: 767px) {
  /* Change the size for mobile devices */
  .poem-banner-image {
    width: 50px;
    /* Set width in percentage */
    height: 50px; }
  .poem-banner-subtitle {
    font-size: 10px; }
  .poem-banner-title h2 {
    font-size: 20px; }
  .poem-page {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
  .poem-banner-content {
    display: flex;
    align-items: start;
    font-size: 1em; } }

.upload-page .container {
  max-width: 800px; }

.upload-button-group {
  margin-top: 40px; }

.upload-button-group .upload-button {
  display: inline-block;
  margin: 0 15px; }

.upload-box {
  padding: 20px; }

.recitation-icon {
  width: 49px; }

.upload-img {
  border-radius: 5px;
  box-shadow: 0 3px 4px 0 #acacac;
  margin-bottom: 20px; }

.user-name {
  color: #26466d;
  font-family: "Avenir Next";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 19px;
  text-align: center; }

.details-form input {
  margin-bottom: 10%; }

.uppercase {
  text-transform: uppercase; }

.form-fields input[type="text"] {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.upload-page .form-fields {
  margin-top: 40px; }

.upload-page .form-field + .form-field {
  margin-top: 20px; }

.upload-page .form-action {
  margin-top: 40px; }

.upload-image {
  width: 100%;
  margin-bottom: 20px; }

.upload-image img {
  max-width: 100%; }

.upload-image button {
  margin-top: 20px; }

.chosen-image {
  position: relative;
  background-color: #fff;
  height: 230px;
  margin: auto;
  overflow: hidden; }

.chosen-image::before {
  position: absolute;
  width: 100%;
  height: 100%;
  border-top: 2px solid #ddd;
  top: 50%;
  left: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.details-form {
  width: 50%;
  margin: auto;
  padding-top: 25px;
  padding-bottom: 25px; }

.half-form {
  width: 49%;
  margin-left: 1%;
  display: inline; }

.legal-text {
  color: #828282;
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.29px;
  line-height: 16px;
  text-align: left; }

.file-picker div {
  display: inline; }

.record-wrapper {
  height: calc(100vh - 146px);
  padding: 0; }

.record-wrapper .wrap {
  width: 100%;
  height: 100%;
  padding: 20px; }

.record-container {
  height: calc(100% - 116px);
  display: flex;
  align-items: center; }

.record-container .record-box {
  /* flex: 1; */ }

.record-container .record-script {
  flex: 1 1;
  margin-left: 80px; }

.upload-control {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: 40px; }
  .upload-control div.DraftEditor-root {
    border: 0.5px solid #838383;
    background-color: #ffffff;
    height: 200px;
    width: 90%;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px; }
  .upload-control .record-tab .nav-tabs .nav-link {
    cursor: pointer;
    font-size: 14px;
    padding: 6px 15px; }
  .upload-control .tab-content {
    width: 100%;
    margin-top: 20px; }
  .upload-control textarea {
    position: absolute;
    width: 100%;
    height: calc(100% - 80px);
    left: 0;
    overflow-y: auto;
    padding: 20px;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2); }

.spaced-item {
  margin-top: 20px; }

.search-poem {
  padding-bottom: 40px; }
  .search-poem form {
    display: flex;
    margin-top: 20px; }
    .search-poem form button {
      margin-left: 10px; }
  .search-poem ul {
    max-height: 300px;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 0; }
    .search-poem ul li {
      list-style: none;
      font-size: 0.9em; }
      .search-poem ul li + li {
        border-top: 1px solid #eee; }
      .search-poem ul li button {
        display: block;
        width: 100%;
        text-align: left;
        padding: 10px 20px;
        color: #000;
        background: none;
        border: 0; }
        .search-poem ul li button:hover {
          background-color: #f8f9fa; }
      .search-poem ul li span {
        display: block;
        margin-top: 5px;
        font-size: 0.8em;
        color: #555; }
  .search-poem .search-list {
    margin-top: 20px; }

#form_2 form {
  cursor: pointer; }
  #form_2 form .ginput_container_fileupload {
    position: relative;
    display: block;
    width: 100%;
    background-color: #F7F7F7; }
    #form_2 form .ginput_container_fileupload .upload-upper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      font-size: 0; }
      #form_2 form .ginput_container_fileupload .upload-upper span {
        margin-bottom: 0; }
      #form_2 form .ginput_container_fileupload .upload-upper .upload-text {
        width: 50%;
        display: inline-block;
        vertical-align: middle;
        color: #000;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 13px; }
        #form_2 form .ginput_container_fileupload .upload-upper .upload-text span {
          color: #BABABA; }
      #form_2 form .ginput_container_fileupload .upload-upper .upload-btn {
        display: inline-block;
        vertical-align: middle;
        width: 120px;
        height: 40px;
        line-height: 40px;
        background-color: #26466d;
        color: #fff;
        font-size: 13px;
        letter-spacing: 1px;
        text-align: center;
        text-transform: uppercase; }
    #form_2 form .ginput_container_fileupload input[type=file] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      padding-top: 126px;
      overflow: hidden; }
    #form_2 form .ginput_container_fileupload .upload-filename {
      display: block;
      width: 100%;
      height: 58px;
      line-height: 58px;
      padding: 0 10px;
      background-color: rgba(216, 216, 216, 0.4);
      font-size: 13px;
      color: #000;
      box-sizing: border-box; }
  #form_2 form:hover .upload-upper .upload-btn {
    background-color: #26466d; }

.continue-button {
  background: none;
  border: none; }

.hide-default-upload {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  width: 1px;
  height: 1px; }

.custom-input {
  display: unset;
  z-index: 99; }

.upload-field {
  margin-top: 20px; }

/* Styles for Tags */
div.ReactTags__tagInput {
  border-radius: 2px; }

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  width: 100%; }

.ReactTags__selected {
  text-align: left; }

div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 5px;
  cursor: move;
  border-radius: 2px; }

div.ReactTags__selected span.ReactTags__tag + span.ReactTags__tag {
  margin-left: 10px; }

div.ReactTags__selected span.ReactTags__tag button {
  border: 0;
  padding: 0;
  margin-left: 5px;
  font-size: 14px; }

div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer; }

div.ReactTags__suggestions {
  position: absolute; }

div.ReactTags__suggestions ul {
  list-style-type: none;
  list-style: none;
  text-align: left;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
  padding: 0; }

div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0; }

div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600; }

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer; }

.group-page {
  padding-top: 0; }

.groups .featured-recordings-item img {
  width: 150px;
  height: 150px; }

div.DraftEditor-root {
  border: 1px solid #838383;
  background-color: #ffffff;
  height: 200px;
  width: 300px;
  overflow-y: auto; }

.group-page.page-wrapper {
  padding-top: 0; }

.group-page .page-title {
  margin-bottom: 0; }

.group-banner-wrapper {
  position: relative;
  width: 100%;
  padding: 50px 0;
  overflow: hidden;
  z-index: 0;
  color: #fff; }

.group-banner-background-false {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }
  .group-banner-background-false img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.group-banner-background-true {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

.group-banner-background-false::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); }

.group-banner-background-true::after {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.group-banner-content {
  display: flex;
  align-items: center;
  font-size: 1.2em; }

.group-banner-image {
  position: relative;
  width: 200px;
  height: 200px;
  border: 3px solid #fff;
  border-radius: 50%;
  overflow: hidden; }
  .group-banner-image img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.group-banner-title {
  flex: 1 1;
  margin-left: 40px; }

.group-banner-cta {
  text-align: center; }

.group-banner-cta button {
  margin-top: 10px; }

.group-header {
  display: flex;
  position: relative; }

.group-container {
  display: flex;
  position: relative; }
  .group-container .group-content {
    display: block;
    flex: 3 1;
    margin-right: 40px; }
  .group-container .group-sidebar {
    flex: 2 1; }
    .group-container .group-sidebar .comments {
      margin-top: 40px;
      padding-top: 0;
      border-top: 0; }
    .group-container .group-sidebar .member-list .member-list-item {
      padding: 10px;
      background-color: #fff; }
    .group-container .group-sidebar .member-list-item a {
      color: #26466d; }
    .group-container .group-sidebar .member-list-item a:hover {
      color: #223e61; }

.group-manage-modal {
  /* set the width of the modal */
  width: 70%;
  /* center the modal horizontally */ }

/* increase the padding and margin of the elements inside the modal */
.group-manage-modal .space-sm {
  margin-bottom: 1rem; }

/* align the elements inside the modal to the left */
.group-manage-modal label,
.group-manage-modal input,
.group-manage-modal textarea,
.group-manage-modal .member-field {
  text-align: left; }

/* align the group picture and cover photo to the center */
.group-manage-modal .main-photo img,
.group-manage-modal img {
  display: block;
  margin: 0 auto; }

.group-manage-modal label {
  font-weight: bold; }

@media (max-width: 767px) {
  .group-banner-image {
    position: absolute;
    width: 75px;
    height: 75px;
    border: 3px solid #fff;
    border-radius: 50%;
    overflow: hidden; }
    .group-banner-image img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .group-manage-modal {
    width: 95%; } }

.reset_password {
  width: "30px";
  margin: "auto";
}

.signup-button {
  paddingtop: "20px";
}

.search-subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-subtitle {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.search-subtitle a {
  font-size: 0.7em;
}

.search-result-section {
  margin-top: 60px;
}

.search-result-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.search-result-item {
  display: flex;
}

.search-result-profile {
  text-align: center;
}

.search-result-profile .profile-image {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto 20px;
  overflow: hidden;
  border-radius: 50%;
  font-size: 0;
}

.search-result-profile .profile-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.result-card-list {
  display: flex;
  text-align: left;
  flex-wrap: wrap;
}

.result-card-item {
  width: 23.5%;
  margin-left: 2%;
}

.result-card-item:nth-child(4n + 1) {
  margin-left: 0;
}

.result-card-item:nth-child(n + 5) {
  margin-top: 20px;
}

.item-image {
  max-width: 150px;
}

.item-info {
  margin-left: 40px;
  text-align: left;
  font-size: 0.8em;
}

.item-info h4 {
  font-size: 1.4em;
}

.item-info span {
  display: inline-block;
  margin-right: 5px;
}

.item-info h5 {
  font-size: 1.2em;
}

.search-result-item img {
  max-width: 100%;
}

.search-result-item + .search-result-item {
  margin-top: 20px;
}

.followers-list {
  max-width: 650px;
}

.followers-list {
  margin-top: 20px;
  list-style: none;
}

.followers-list-item + .followers-list-item {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.followers-user {
  display: flex;
  align-items: center;
  width: 100%;
}

.followers-user .user-image {
  position: relative;
  width: 60px;
  height: 60px;
}

.followers-user .user-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.followers-user .user-info {
  flex: 1 1;
  margin: 0 20px;
}

.followers-user .user-info p {
  margin-bottom: 0;
}

.followers-user .user-info time {
  font-size: 0.9em;
  color: #777;
}

.followers-user .user-link {
  justify-items: flex-end;
}

.following-list {
  max-width: 650px;
}

.following-list {
  margin-top: 20px;
  list-style: none;
}

.following-list-item + .following-list-item {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #777;
}

.following-user {
  display: flex;
  align-items: center;
  width: 100%;
}

.following-user .user-image {
  position: relative;
  width: 60px;
  height: 60px;
}

.following-user .user-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.following-user .user-info {
  flex: 1 1;
  margin: 0 20px;
}

.following-user .user-info p {
  margin-bottom: 0;
}

.following-user .user-info time {
  font-size: 0.9em;
  color: #777;
}

.following-user .user-link {
  justify-items: flex-end;
}

.playlist-banner .wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap; }

.playlist-banner-body {
  display: flex;
  width: calc(100% - 250px);
  justify-content: space-between; }

.playlist-covers {
  position: relative;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  list-style: none;
  font-size: 0;
  background-color: #fff;
  border-radius: 100px;
  overflow: hidden; }
  .playlist-covers .playlist-cover {
    position: absolute;
    width: calc(50% - 1px);
    height: calc(50% - 1px);
    background-color: #efefef;
    overflow: hidden; }
    .playlist-covers .playlist-cover:nth-child(1) {
      top: 0;
      left: 0; }
    .playlist-covers .playlist-cover:nth-child(2) {
      top: 0;
      right: 0; }
    .playlist-covers .playlist-cover:nth-child(3) {
      bottom: 0;
      left: 0; }
    .playlist-covers .playlist-cover:nth-child(4) {
      bottom: 0;
      right: 0; }
    .playlist-covers .playlist-cover img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.playlist-info {
  width: calc(100% - 250px); }

.playlist-info {
  color: #777; }

.playlist-info h1 {
  color: #26466d;
  text-transform: uppercase;
  letter-spacing: 1px; }

.playlist-add {
  display: flex;
  width: calc(100%);
  justify-content: right;
  padding: 10px; }

.modal-buttons {
  padding-right: 10px;
  overflow: hidden;
  width: auto;
  display: inline; }

.buttons {
  float: right;
  margin-left: 0; }

/* discussion Banner Style */
.discussion-banner {
  display: block;
  margin-bottom: 40px;
  align-items: center;
  text-align: left; }
  .discussion-banner .discussion-banner-image {
    width: 120px;
    background-color: #efefef; }
    .discussion-banner .discussion-banner-image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .discussion-banner .discussion-banner-content {
    margin-left: 40px; }
    .discussion-banner .discussion-banner-content h2 {
      color: #26466d;
      text-transform: uppercase;
      letter-spacing: 2px;
      display: flex;
      align-items: center; }
      .discussion-banner .discussion-banner-content h2 .play-button-discussion {
        margin-left: 20px; }

.discussion-container {
  text-align: left; }

.discussion-intro {
  display: flex;
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
  line-height: 1.2; }
  .discussion-intro li + li {
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid #999; }

.discussion-background {
  height: 230px;
  width: auto;
  object-fit: contain; }

.discussion-title {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 27px; }

.discussion-user {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 19px;
  text-align: center; }

.play-number {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1.67px;
  line-height: 27px;
  text-align: center; }

.total-plays {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center; }

.discussion-author-profile-img {
  padding: 3px;
  width: 150px;
  height: 150px; }

.recitation-author-profile-img {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.discussion-author-profile-img-inner {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.progress {
  height: 5px; }

.progress-bar {
  background-color: #000;
  height: 5px; }

.discussion-text {
  height: 470px;
  overflow: scroll; }

.display-linebreak {
  white-space: pre-line; }

.discussion-content {
  display: flex;
  position: relative; }

.discussion-detail {
  flex: 2 1;
  margin-right: 40px; }

.discussion-sidebar {
  flex: 1 1; }

.discussion-script {
  margin: 20px 0;
  white-space: pre-line; }
  .discussion-script .ql-editor {
    padding: 0; }
  .discussion-script .ql-container {
    border: none !important; }

.discussion-actions {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #212529;
  margin-bottom: 20px;
  background-color: #f8f9fa;
  padding: 10px; }
  .discussion-actions button {
    display: flex;
    align-items: center;
    margin-right: 60px;
    padding: 0;
    border: 0;
    background: none;
    line-height: 1; }
  .discussion-actions a {
    color: #212529; }
    .discussion-actions a:hover {
      color: #223e61; }
  .discussion-actions img, .discussion-actions svg {
    display: inline-block;
    margin-right: 10px; }

.discussion-actions-list li {
  padding: 0; }

.discussion-actions-list .discussion-action-btn {
  display: flex;
  width: 100%;
  align-items: center;
  background: none;
  border: 0;
  color: #212529;
  font-size: 16px;
  line-height: 1;
  margin: 0;
  padding: 15px 20px; }
  .discussion-actions-list .discussion-action-btn:hover {
    background-color: #f8f9fa; }
  .discussion-actions-list .discussion-action-btn img, .discussion-actions-list .discussion-action-btn i {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 15px;
    font-size: 22px;
    line-height: 0.7;
    color: #26466d; }
  .discussion-actions-list .discussion-action-btn p {
    margin-bottom: 0; }

.button-dark-blue {
  color: #26466d;
  background-color: #fffaf0; }

.audio-player {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px; }

.play-button-discussion {
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  background-color: #31c27c;
  border-radius: 50%;
  color: #fff;
  font-size: 10px; }
  .play-button-discussion:hover {
    background-color: #223e61; }
  .play-button-discussion svg {
    height: 25px;
    margin-left: 5px;
    color: #fff; }
  .play-button-discussion.small {
    width: 20px;
    height: 20px; }
    .play-button-discussion.small svg {
      height: 20px;
      margin-left: 2px; }

.discussion-more-readings .section-title {
  margin-bottom: 20px; }

.discussion-more-readings .item-info h5 {
  display: flex;
  justify-content: space-between;
  font-size: 1.1em;
  margin-bottom: 10px; }
  .discussion-more-readings .item-info h5 a {
    color: #26466d; }
    .discussion-more-readings .item-info h5 a:hover {
      color: #223e61; }

.discussion-more-readings .item-info p {
  font-size: 11px; }
  .discussion-more-readings .item-info p b {
    color: #26466d; }
  .discussion-more-readings .item-info p a {
    font-weight: 400;
    color: #333; }
    .discussion-more-readings .item-info p a:hover {
      color: #26466d; }

.discussion-more-readings .item-cta {
  font-size: 11px; }

.rec-action-buttons {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.75px;
  line-height: 12px;
  text-align: center;
  padding-top: 5px; }

.hover-link {
  color: black !important; }

.hover-link:hover {
  color: blue !important; }

.poem-script {
  margin: 20px 0;
  white-space: pre-line; }
  .poem-script .title {
    font-size: xx-large; }
  .poem-script .ql-editor {
    padding: 0; }
  .poem-script .ql-snow .ql-editor h1 {
    font-size: 3em; }
  .poem-script .ql-snow .ql-editor h3 {
    font-size: 1.3em; }
  .poem-script .ql-container {
    border: none !important; }

.project-script {
  white-space: normal; }
  .project-script .ql-editor {
    padding: 0; }
  .project-script .ql-container {
    border: none !important; }
  .project-script p {
    font-size: medium; }
  .project-script li {
    font-size: medium; }

.project-page.page-wrapper {
  margin-top: 0;
  padding-top: 0; }

.project-page {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }

.notifications-content {
  max-width: 650px;
}

.notifications-list {
  margin-top: 20px;
  list-style: none;
}

.notifications-list-item + .notifications-list-item {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
}

.notifications-list .notification-user {
  display: flex;
  align-items: center;
  width: 100%;
}

.notification-user .user-image {
  position: relative;
  width: 60px;
  height: 60px;
}

.notification-user .user-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.notification-user .user-info {
  flex: 1 1;
  margin: 0 20px;
}

.notification-user .user-info p {
  margin-bottom: 10px;
}

.notification-user .user-info time {
  font-size: 0.9em;
  color: #777;
}
.notification-user .user-link {
  justify-items: flex-end;
}

.rc-slider {
  border-radius: 6px;
  height: 14px;
  padding: 5px 0;
  position: relative;
  touch-action: none;
  width: 100%;
}
.rc-slider,
.rc-slider * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}
.rc-slider-rail {
  background-color: #e9e9e9;
  width: 100%;
}
.rc-slider-rail,
.rc-slider-track {
  border-radius: 6px;
  height: 4px;
  position: absolute;
}
.rc-slider-track {
  background-color: #abe2fb;
  left: 0;
}
.rc-slider-handle {
  background-color: #fff;
  border: 2px solid #96dbfa;
  border-radius: 50%;
  cursor: pointer;
  cursor: grab;
  height: 14px;
  margin-top: -5px;
  position: absolute;
  touch-action: pan-x;
  width: 14px;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
}
.rc-slider-handle:focus {
  outline: none;
}
.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: #57c5f7;
}
.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: grabbing;
}
.rc-slider-mark {
  font-size: 12px;
  left: 0;
  position: absolute;
  top: 18px;
  width: 100%;
}
.rc-slider-mark-text {
  color: #999;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  text-align: center;
  vertical-align: middle;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  background: transparent;
  height: 4px;
  position: absolute;
  width: 100%;
}
.rc-slider-dot {
  background-color: #fff;
  border: 2px solid #e9e9e9;
  border-radius: 50%;
  bottom: -2px;
  cursor: pointer;
  height: 8px;
  margin-left: -4px;
  position: absolute;
  vertical-align: middle;
  width: 8px;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-dot-reverse {
  margin-right: -4px;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-dot,
.rc-slider-disabled .rc-slider-handle {
  background-color: #fff;
  border-color: #ccc;
  box-shadow: none;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-dot,
.rc-slider-disabled .rc-slider-mark-text {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  height: 100%;
  padding: 0 5px;
  width: 14px;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  bottom: 0;
  left: 5px;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  height: 100%;
  left: 18px;
  top: 0;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-appear,
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
  display: block !important;
}
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active,
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
          animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
          animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-appear,
.rc-slider-tooltip-zoom-down-enter {
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform: scale(0);
          transform: scale(0);
}
.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@-webkit-keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
.rc-slider-tooltip {
  left: -9999px;
  position: absolute;
  top: -9999px;
  visibility: visible;
}
.rc-slider-tooltip,
.rc-slider-tooltip * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px;
}
.rc-slider-tooltip-inner {
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
  color: #fff;
  font-size: 12px;
  height: 24px;
  line-height: 1;
  min-width: 24px;
  padding: 6px 2px;
  text-align: center;
  text-decoration: none;
}
.rc-slider-tooltip-arrow {
  border-color: transparent;
  border-style: solid;
  height: 0;
  position: absolute;
  width: 0;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #6c6c6c;
  border-width: 4px 4px 0;
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
}
.rc-switch {
  background-color: #ccc;
  border: 1px solid #ccc;
  border-radius: 20px 20px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: 22px;
  line-height: 20px;
  padding: 0;
  position: relative;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  vertical-align: middle;
  width: 44px;
}
.rc-switch-inner {
  color: #fff;
  font-size: 12px;
  left: 24px;
  position: absolute;
  top: 0;
}
.rc-switch:after {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: rcSwitchOff;
          animation-name: rcSwitchOff;
  -webkit-animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
          animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  background-color: #fff;
  border-radius: 50% 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  content: " ";
  cursor: pointer;
  height: 18px;
  left: 2px;
  position: absolute;
  top: 1px;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  width: 18px;
}
.rc-switch:hover:after {
  -webkit-animation-name: rcSwitchOn;
          animation-name: rcSwitchOn;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.rc-switch:focus {
  box-shadow: 0 0 0 2px #d5f1fd;
  outline: none;
}
.rc-switch-checked {
  background-color: #87d068;
  border: 1px solid #87d068;
}
.rc-switch-checked .rc-switch-inner {
  left: 6px;
}
.rc-switch-checked:after {
  left: 22px;
}
.rc-switch-disabled {
  background: #ccc;
  border-color: #ccc;
  cursor: no-drop;
}
.rc-switch-disabled:after {
  -webkit-animation-name: none;
          animation-name: none;
  background: #9e9e9e;
  cursor: no-drop;
}
.rc-switch-disabled:hover:after {
  -webkit-animation-name: none;
          animation-name: none;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.rc-switch-label {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  padding-left: 10px;
  pointer-events: none;
  -webkit-user-select: text;
          user-select: text;
  vertical-align: middle;
  white-space: normal;
}
@-webkit-keyframes rcSwitchOn {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
  to {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}
@keyframes rcSwitchOn {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
  to {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}
@-webkit-keyframes rcSwitchOff {
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes rcSwitchOff {
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.react-jinke-music-player-main:focus {
  outline: none;
}
.react-jinke-music-player-main li,
.react-jinke-music-player-main ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.react-jinke-music-player-main * {
  box-sizing: border-box;
}
.react-jinke-music-player-main .text-center {
  text-align: center;
}
.react-jinke-music-player-main .hidden {
  display: none !important;
}
.react-jinke-music-player-main .loading {
  -webkit-animation: audioLoading 1s linear infinite;
          animation: audioLoading 1s linear infinite;
  display: inline-flex;
}
.react-jinke-music-player-main .loading svg {
  color: #31c27c;
  font-size: 24px;
}
.react-jinke-music-player-main .translate {
  -webkit-animation: translate 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: translate 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-main .scale {
  -webkit-animation: scaleTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: scaleTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
@-webkit-keyframes playing {
  to {
    -webkit-transform: rotateX(1turn);
            transform: rotateX(1turn);
  }
}
@keyframes playing {
  to {
    -webkit-transform: rotateX(1turn);
            transform: rotateX(1turn);
  }
}
@-webkit-keyframes coverReset {
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes coverReset {
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@-webkit-keyframes audioLoading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}
@keyframes audioLoading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}
@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 0.6;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2);
  }
}
@keyframes scale {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 0.6;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2);
  }
}
@-webkit-keyframes scaleTo {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scaleTo {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes scaleFrom {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes scaleFrom {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes imgRotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}
@keyframes imgRotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}
@-webkit-keyframes fromTo {
  0% {
    -webkit-transform: scale(1) translate3d(0, 110%, 0);
            transform: scale(1) translate3d(0, 110%, 0);
  }
  to {
    -webkit-transform: scale(1) translateZ(0);
            transform: scale(1) translateZ(0);
  }
}
@keyframes fromTo {
  0% {
    -webkit-transform: scale(1) translate3d(0, 110%, 0);
            transform: scale(1) translate3d(0, 110%, 0);
  }
  to {
    -webkit-transform: scale(1) translateZ(0);
            transform: scale(1) translateZ(0);
  }
}
@-webkit-keyframes fromOut {
  0% {
    -webkit-transform: scale(1) translateZ(0);
            transform: scale(1) translateZ(0);
  }
  to {
    -webkit-transform: scale(1) translate3d(0, 110%, 0);
            transform: scale(1) translate3d(0, 110%, 0);
  }
}
@keyframes fromOut {
  0% {
    -webkit-transform: scale(1) translateZ(0);
            transform: scale(1) translateZ(0);
  }
  to {
    -webkit-transform: scale(1) translate3d(0, 110%, 0);
            transform: scale(1) translate3d(0, 110%, 0);
  }
}
@-webkit-keyframes fromDown {
  0% {
    -webkit-transform: scale(1) translate3d(0, -110%, 0);
            transform: scale(1) translate3d(0, -110%, 0);
  }
  to {
    -webkit-transform: scale(1) translateZ(0);
            transform: scale(1) translateZ(0);
  }
}
@keyframes fromDown {
  0% {
    -webkit-transform: scale(1) translate3d(0, -110%, 0);
            transform: scale(1) translate3d(0, -110%, 0);
  }
  to {
    -webkit-transform: scale(1) translateZ(0);
            transform: scale(1) translateZ(0);
  }
}
@-webkit-keyframes translate {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
}
@keyframes translate {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
}
@-webkit-keyframes remove {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
}
@keyframes remove {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
}
.react-jinke-music-player-main .img-rotate-pause {
  -webkit-animation-play-state: paused !important;
          animation-play-state: paused !important;
}
.react-jinke-music-player-main .img-rotate-reset {
  -webkit-animation: coverReset 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards !important;
          animation: coverReset 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards !important;
}
.react-jinke-music-player-mobile {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 30px;
  margin-top: 70px;
  overflow: hidden;
  padding: 60px;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}
.react-jinke-music-player-mobile > .group {
  flex: 1 1 auto;
}
.react-jinke-music-player-mobile .show {
  -webkit-animation: mobile-bg-show 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: mobile-bg-show 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile .hide {
  -webkit-animation: mobile-bg-hide 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: mobile-bg-hide 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile-play-model-tip {
  align-items: center;
  background-color: #31c27c;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  color: #fff;
  display: flex;
  height: 35px;
  left: 30px;
  margin-top: 70px;
  line-height: 35px;
  padding: 0 60px;
  position: fixed;
  top: 0;
  -webkit-transform: translate3d(0, -105%, 0);
          transform: translate3d(0, -105%, 0);
  transition: -webkit-transform 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  transition: transform 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  transition: transform 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1), -webkit-transform 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 100%;
  z-index: 1000;
}
.react-jinke-music-player-mobile-play-model-tip-title {
  margin-right: 12px;
}
.react-jinke-music-player-mobile-play-model-tip-title svg {
  -webkit-animation: none !important;
          animation: none !important;
  vertical-align: text-bottom !important;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-mobile-play-model-tip-title svg {
    color: #fff !important;
    font-size: 19px;
  }
}
.react-jinke-music-player-mobile-play-model-tip-title .loop-btn {
  display: flex;
}
.react-jinke-music-player-mobile-play-model-tip-text {
  font-size: 14px;
}
.react-jinke-music-player-mobile-play-model-tip.show {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.react-jinke-music-player-mobile-header {
  align-items: center;
  -webkit-animation: fromDown 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: fromDown 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  display: flex;
  justify-content: center;
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
}
.react-jinke-music-player-mobile-header-title {
  font-size: 20px;
  overflow: hidden;
  padding: 0 30px;
  text-align: center;
  text-overflow: ellipsis;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  white-space: nowrap;
}
.react-jinke-music-player-mobile-header .item {
  display: inline-flex;
  width: 50px;
}
.react-jinke-music-player-mobile-header-right {
  color: hsla(0, 0%, 100%, 0.6);
  cursor: pointer;
  position: absolute;
  right: 0;
}
.react-jinke-music-player-mobile-header-right svg {
  font-size: 25px;
}
.react-jinke-music-player-mobile-singer {
  -webkit-animation: fromDown 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: fromDown 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  padding: 12px 0;
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-mobile-singer {
    padding: 0;
  }
}
.react-jinke-music-player-mobile-singer-name {
  font-size: 14px;
  position: relative;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-mobile-singer-name:after,
.react-jinke-music-player-mobile-singer-name:before {
  background-color: hsla(0, 0%, 100%, 0.6);
  border-radius: 2px;
  content: "";
  height: 1px;
  position: absolute;
  top: 9px;
  transition: background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 16px;
}
.react-jinke-music-player-mobile-singer-name:after {
  left: -25px;
}
.react-jinke-music-player-mobile-singer-name:before {
  right: -25px;
}
.react-jinke-music-player-mobile-cover {
  -webkit-animation: fromTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: fromTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  border: 5px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.1);
  height: 300px;
  margin: 15px auto;
  overflow: hidden;
  transition: box-shadow, border 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 300px;
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-mobile-cover {
    height: 230px;
    margin: 10px auto;
    width: 230px;
  }
}
.react-jinke-music-player-mobile-cover .cover {
  -webkit-animation: imgRotate 30s linear infinite;
          animation: imgRotate 30s linear infinite;
  object-fit: cover;
  width: 100%;
}
.react-jinke-music-player-mobile-progress {
  align-items: center;
  display: flex;
  justify-content: space-around;
}
.react-jinke-music-player-mobile-progress .current-time,
.react-jinke-music-player-mobile-progress .duration {
  color: hsla(0, 0%, 100%, 0.6);
  display: inline-flex;
  font-size: 12px;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 55px;
}
.react-jinke-music-player-mobile-progress .current-time {
  margin-right: 5px;
}
.react-jinke-music-player-mobile-progress .duration {
  justify-content: flex-end;
  margin-left: 5px;
}
.react-jinke-music-player-mobile-progress .progress-bar {
  flex: 1 1 auto;
}
.react-jinke-music-player-mobile-progress .rc-slider-rail {
  background-color: hsla(0, 0%, 100%, 0.6);
}
.react-jinke-music-player-mobile-progress .rc-slider-handle,
.react-jinke-music-player-mobile-progress .rc-slider-track {
  background-color: #31c27c;
}
.react-jinke-music-player-mobile-progress .rc-slider-handle {
  border: 2px solid #fff;
}
.react-jinke-music-player-mobile-progress .rc-slider-handle:active {
  box-shadow: 0 0 2px #31c27c;
}
.react-jinke-music-player-mobile-progress-bar {
  display: flex;
  position: relative;
  width: 100%;
}
.react-jinke-music-player-mobile-progress-bar .progress-load-bar {
  background-color: rgba(0, 0, 0, 0.06);
  height: 4px;
  left: 0;
  position: absolute;
  top: 5px;
  transition: width, background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 0;
  z-index: 77;
}
.react-jinke-music-player-mobile-switch {
  -webkit-animation: fromDown 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: fromDown 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile-toggle {
  padding: 17px 0;
}
.react-jinke-music-player-mobile-toggle .group {
  cursor: pointer;
}
.react-jinke-music-player-mobile-toggle .group svg {
  font-size: 40px;
}
.react-jinke-music-player-mobile-toggle .play-btn {
  padding: 0 40px;
}
.react-jinke-music-player-mobile-toggle .play-btn svg {
  font-size: 45px;
}
.react-jinke-music-player-mobile-toggle .loading-icon {
  padding: 0 40px;
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-mobile-toggle {
    padding: 10px 0;
  }
  .react-jinke-music-player-mobile-toggle > .group svg {
    font-size: 32px;
  }
  .react-jinke-music-player-mobile-toggle .play-btn svg {
    font-size: 40px;
  }
}
.react-jinke-music-player-mobile-operation,
.react-jinke-music-player-mobile-progress,
.react-jinke-music-player-mobile-toggle {
  -webkit-animation: fromTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: fromTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.react-jinke-music-player-mobile-operation .items {
  align-items: center;
  display: flex;
  justify-content: space-around;
}
.react-jinke-music-player-mobile-operation .items .item {
  cursor: pointer;
  flex: 1 1;
  text-align: center;
}
.react-jinke-music-player-mobile-operation .items .item svg {
  color: hsla(0, 0%, 100%, 0.6);
}
@-webkit-keyframes mobile-bg-show {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mobile-bg-show {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes mobile-bg-hide {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mobile-bg-hide {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.audio-lists-panel-sortable-highlight-bg {
  background-color: rgba(49, 194, 124, 0.15) !important;
}
.audio-lists-panel {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px 4px 0 0;
  bottom: 80px;
  color: hsla(0, 0%, 100%, 0.8);
  display: none\9;
  height: 410px;
  overflow: hidden;
  position: fixed;
  right: 33px;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
  transition: background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 480px;
  z-index: 999;
}
.audio-lists-panel svg {
  font-size: 24px;
}
.audio-lists-panel.show {
  -webkit-animation: scaleTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: scaleTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  display: block\9;
}
.audio-lists-panel.hide {
  -webkit-animation: scaleFrom 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: scaleFrom 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  display: none\9;
}
.audio-lists-panel-mobile {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 0;
  bottom: 0;
  height: auto !important;
  left: 30px;
  margin-top: 70px;

  padding: 60px;
  right: 0;
  top: 0;
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  width: 100% !important;
}
.audio-lists-panel-mobile.show {
  -webkit-animation: fromTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: fromTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  display: block\9;
}
.audio-lists-panel-mobile.hide {
  -webkit-animation: fromOut 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: fromOut 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
  display: none\9;
}
.audio-lists-panel-mobile
  .audio-item:not(.audio-lists-panel-sortable-highlight-bg) {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
.audio-lists-panel-mobile
  .audio-item:not(.audio-lists-panel-sortable-highlight-bg).playing {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.audio-lists-panel-mobile .audio-lists-panel-content {
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 50px) !important;
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  width: 100% !important;
}
.audio-lists-panel-header {
  border-bottom: 1px solid rgba(3, 3, 3, 0.75);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: background-color,
    border-bottom 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-header-close-btn,
.audio-lists-panel-header-delete-btn {
  cursor: pointer;
  display: flex;
}
.audio-lists-panel-header-delete-btn svg {
  font-size: 21px;
}
@media screen and (max-width: 767px) {
  .audio-lists-panel-header-delete-btn svg {
    font-size: 19px;
  }
}
@media screen and (min-width: 768px) {
  .audio-lists-panel-header-close-btn:hover svg {
    -webkit-animation: imgRotate 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
            animation: imgRotate 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  }
}
.audio-lists-panel-header-line {
  background: #fff;
  height: 20px;
  margin: 0 10px;
  width: 1px;
}
.audio-lists-panel-header-title {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  margin: 0;
  padding: 0 20px;
  text-align: left;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-header-num {
  margin-left: 10px;
}
.audio-lists-panel-header-actions {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.audio-lists-panel-content {
  height: 359px;
  overflow-x: hidden;
  overflow-y: auto;
}
.audio-lists-panel-content.no-content {
  align-items: center;
  display: flex;
  justify-content: center;
}
.audio-lists-panel-content.no-content > span {
  display: flex;
}
.audio-lists-panel-content .no-data {
  margin-left: 10px;
}
.audio-lists-panel-content .audio-item {
  align-items: center;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  line-height: 40px;
  padding: 3px 20px;
  transition: background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.audio-lists-panel-content .audio-item:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}
.audio-lists-panel-content .audio-item.playing {
  background-color: rgba(0, 0, 0, 0.35);
}
.audio-lists-panel-content .audio-item.playing,
.audio-lists-panel-content .audio-item.playing svg {
  color: #31c27c;
}
.audio-lists-panel-content .audio-item.remove {
  -webkit-animation: remove 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
          animation: remove 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1) forwards;
}
.audio-lists-panel-content .audio-item .player-icons {
  display: inline-flex;
  width: 30px;
}
.audio-lists-panel-content .audio-item .player-icons .loading {
  -webkit-animation: audioLoading 1s linear infinite;
          animation: audioLoading 1s linear infinite;
}
.audio-lists-panel-content .audio-item:active,
.audio-lists-panel-content .audio-item:hover {
  background-color: rgba(0, 0, 0, 0.35);
}
.audio-lists-panel-content
  .audio-item:active
  .group:not([class=".player-delete"])
  svg,
.audio-lists-panel-content
  .audio-item:hover
  .group:not([class=".player-delete"])
  svg {
  color: #31c27c;
}
.audio-lists-panel-content .audio-item .group {
  display: inline-flex;
}
.audio-lists-panel-content .audio-item .player-name {
  flex: 1 1;
  padding: 0 20px 0 10px;
}
.audio-lists-panel-content .audio-item .player-name,
.audio-lists-panel-content .audio-item .player-singer {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  white-space: nowrap;
}
.audio-lists-panel-content .audio-item .player-singer {
  font-size: 12px;
  width: 85px;
}
.audio-lists-panel-content .audio-item .player-delete {
  justify-content: flex-end;
  text-align: right;
  width: 30px;
}
.audio-lists-panel-content .audio-item .player-delete:hover svg {
  -webkit-animation: imgRotate 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
          animation: imgRotate 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main {
  font-family: inherit;
  touch-action: none;
}
.react-jinke-music-player-main ::-webkit-scrollbar-thumb {
  background-color: #31c27c;
  height: 20px;
  opacity: 0.5;
}
.react-jinke-music-player-main ::-webkit-scrollbar {
  background-color: #f7f8fa;
  width: 8px;
}
.react-jinke-music-player-main .rc-switch:focus {
  box-shadow: none;
}
.react-jinke-music-player-main .lyric-btn svg {
  font-size: 20px;
}
.react-jinke-music-player-main .lyric-btn-active,
.react-jinke-music-player-main .lyric-btn-active svg {
  color: #31c27c !important;
}
.react-jinke-music-player-main .music-player-lyric {
  background: transparent;
  bottom: 100px;
  color: #31c27c;
  cursor: move;
  font-size: 36px;
  left: 0;
  position: fixed;
  text-align: center;
  text-shadow: 0 1px 1px hsla(0, 0%, 100%, 0.05);
  transition: box-shadow 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 100%;
  z-index: 998;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main .music-player-lyric {
    font-size: 16px;
    z-index: 999;
  }
}
.react-jinke-music-player-main .play-mode-title {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 80px;
  color: #fff;
  line-height: 1.5;
  opacity: 0;
  padding: 5px 20px;
  pointer-events: none;
  position: fixed;
  right: 72px;
  text-align: center;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  transition: all 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  -webkit-user-select: none;
          user-select: none;
  visibility: hidden;
  z-index: 1000;
}
.react-jinke-music-player-main .play-mode-title.play-mode-title-visible {
  opacity: 1;
  pointer-events: all;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  visibility: visible;
}
.react-jinke-music-player-main .glass-bg-container {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-filter: blur(80px);
          filter: blur(80px);
  height: 300%;
  left: 0;
  position: absolute;
  top: 0;
  width: 300%;
  z-index: -1;
}
.react-jinke-music-player-main .glass-bg {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
.react-jinke-music-player-main svg {
  font-size: 24px;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main svg:active,
.react-jinke-music-player-main svg:hover {
  color: #31c27c;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main svg {
    font-size: 22px;
  }
}
.react-jinke-music-player-main .react-jinke-music-player-mode-icon {
  -webkit-animation: scaleTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
          animation: scaleTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main .music-player-panel {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  box-shadow: 0 0 3px #403f3f;
  color: #fff;
  height: 80px;
  left: 0;
  position: fixed;
  transition: background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 100%;
  z-index: 99;
}
.react-jinke-music-player-main .music-player-panel .panel-content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  padding: 0 30px;
  position: relative;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-rail {
  background-color: hsla(0, 0%, 100%, 0.6);
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-handle,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-track {
  background-color: #31c27c;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-handle {
  border: 2px solid #fff;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-handle:active {
  box-shadow: 0 0 2px #31c27c;
}
.react-jinke-music-player-main .music-player-panel .panel-content .img-content {
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 34, 77, 0.05);
  cursor: pointer;
  height: 50px;
  overflow: hidden;
  width: 50px;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .img-content {
    height: 40px;
    width: 40px;
  }
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .img-content
  img {
  width: 100%;
}
.react-jinke-music-player-main .music-player-panel .panel-content .img-rotate {
  -webkit-animation: imgRotate 15s linear infinite;
          animation: imgRotate 15s linear infinite;
}
.react-jinke-music-player-main .music-player-panel .panel-content .hide-panel,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .upload-music {
  cursor: pointer;
  flex-basis: 10%;
  margin-left: 15px;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .progress-bar-content {
    display: none !important;
  }
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content {
  flex: 1 1;
  overflow: hidden;
  padding: 0 20px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-title {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  white-space: nowrap;
  width: 100%;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main {
  display: inline-flex;
  justify-content: center;
  margin-top: 6px;
  width: 100%;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main
  .current-time,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main
  .duration {
  flex-basis: 5%;
  font-size: 12px;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar {
  flex: 1 1 auto;
  margin: 2px 20px 0;
  position: relative;
  width: 100%;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .progress {
  background: linear-gradient(
    135deg,
    transparent,
    transparent 31%,
    rgba(0, 0, 0, 0.05) 33%,
    rgba(0, 0, 0, 0.05) 67%,
    transparent 69%
  );
  background-color: #31c27c;
  display: inline-block;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  transition: width 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .progress
  .progress-change {
  background-color: #fff;
  border-radius: 50%;
  bottom: -2px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  height: 10px;
  position: absolute;
  right: 0;
  width: 10px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .progress-load-bar {
  background-color: rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  height: 4px;
  left: 0;
  position: absolute;
  top: 5px;
  transition: width, background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 0;
  z-index: 77;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .rc-slider-track {
  z-index: 78;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .rc-slider-handle {
  z-index: 79;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content {
  align-content: center;
  align-items: center;
  display: inline-flex;
  flex-basis: 15%;
  padding-left: 5%;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group {
  align-items: center;
  display: inline-flex;
  flex: 1 1;
  justify-content: center;
  margin: 0 10px;
  text-align: center;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group
  > svg {
  cursor: pointer;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group
  .group {
  display: flex;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .player-content
    > .group {
    margin: 0 6px;
  }
}
@media screen and (max-width: 320px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .player-content
    > .group {
    margin: 0 4px;
  }
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  > .group
  > i {
  color: #31c27c;
  cursor: pointer;
  font-size: 25px;
  vertical-align: middle;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .theme-switch
  .rc-switch {
  background-color: transparent;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .player-content
    .play-sounds {
    display: none !important;
  }
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .next-audio
  svg,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .prev-audio
  svg {
  font-size: 35px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .loading-icon,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-btn {
  padding: 0 18px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-btn
  svg {
  font-size: 26px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .loop-btn.active {
  color: #31c27c;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds {
  align-items: center;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds
  svg {
  font-size: 28px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds
  .sounds-icon {
  display: flex;
  flex: 1 1 auto;
  margin-right: 15px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-sounds
  .sound-operation {
  width: 100px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .destroy-btn
  svg {
  font-size: 28px;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 40px;
  box-shadow: 0 0 1px 1px hsla(0, 0%, 100%, 0.02);
  height: 28px;
  min-width: 60px;
  padding: 0 10px;
  position: relative;
  transition: color, background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  -webkit-user-select: none;
          user-select: none;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn
  .audio-lists-icon {
  display: flex;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn
  > .group:hover,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn
  > .group:hover
  > svg {
  color: #31c27c;
}
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn
  .audio-lists-num {
  margin-left: 8px;
}
.react-jinke-music-player-main .music-player-panel .rc-switch-inner svg {
  font-size: 13px;
}
.react-jinke-music-player-main .rc-slider-rail {
  background-color: #fff !important;
  transition: background-color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main.light-theme svg {
  color: #31c27c;
}
.react-jinke-music-player-main.light-theme svg:active,
.react-jinke-music-player-main.light-theme svg:hover {
  color: #3ece89;
}
.react-jinke-music-player-main.light-theme .rc-slider-rail {
  background-color: rgba(0, 0, 0, 0.09) !important;
}
.react-jinke-music-player-main.light-theme .music-player-controller {
  background-color: #fff;
  border-color: #fff;
}
.react-jinke-music-player-main.light-theme .music-player-panel {
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 34, 77, 0.05);
  color: #7d7d7d;
}
.react-jinke-music-player-main.light-theme .music-player-panel .img-content {
  box-shadow: 0 0 10px #dcdcdc;
}
.react-jinke-music-player-main.light-theme
  .music-player-panel
  .progress-load-bar {
  background-color: rgba(0, 0, 0, 0.06) !important;
}
.react-jinke-music-player-main.light-theme .rc-switch {
  color: #fff;
}
.react-jinke-music-player-main.light-theme .rc-switch:after {
  background-color: #fff;
}
.react-jinke-music-player-main.light-theme .rc-switch-checked {
  background-color: #31c27c !important;
  border: 1px solid #31c27c;
}
.react-jinke-music-player-main.light-theme .rc-switch-inner {
  color: #fff;
}
.react-jinke-music-player-main.light-theme .audio-lists-btn {
  background-color: #f7f8fa !important;
}
.react-jinke-music-player-main.light-theme .audio-lists-btn:active,
.react-jinke-music-player-main.light-theme .audio-lists-btn:hover {
  background-color: #fdfdfe;
  color: #444;
}
.react-jinke-music-player-main.light-theme .audio-lists-btn > .group:hover,
.react-jinke-music-player-main.light-theme
  .audio-lists-btn
  > .group:hover
  > svg {
  color: #444;
}
.react-jinke-music-player-main.light-theme .audio-lists-panel {
  background-color: #fff;
  box-shadow: 0 0 2px #dcdcdc;
  color: #444;
}
.react-jinke-music-player-main.light-theme .audio-lists-panel .audio-item {
  background-color: #fff;
}
.react-jinke-music-player-main.light-theme
  .audio-lists-panel
  .audio-item:nth-child(odd) {
  background-color: #fafafa !important;
}
.react-jinke-music-player-main.light-theme
  .audio-lists-panel
  .audio-item.playing {
  background-color: #f2f2f2 !important;
}
.react-jinke-music-player-main.light-theme
  .audio-lists-panel
  .audio-item.playing,
.react-jinke-music-player-main.light-theme
  .audio-lists-panel
  .audio-item.playing
  svg {
  color: #31c27c !important;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player-main.light-theme .audio-lists-panel .audio-item {
    background-color: #fff !important;
  }
  .react-jinke-music-player-main.light-theme
    .audio-lists-panel
    .audio-item.playing {
    background-color: #f2f2f2 !important;
  }
  .react-jinke-music-player-main.light-theme
    .audio-lists-panel
    .audio-item.playing,
  .react-jinke-music-player-main.light-theme
    .audio-lists-panel
    .audio-item.playing
    svg {
    color: #31c27c !important;
  }
}
.react-jinke-music-player-main.light-theme .audio-lists-panel-header {
  background-color: #fff;
  border-bottom: 1px solid #f4f4f7;
  color: #444;
}
.react-jinke-music-player-main.light-theme .audio-lists-panel-header-line {
  background-color: #f4f4f7;
}
.react-jinke-music-player-main.light-theme .audio-item {
  background-color: rgba(64, 68, 75, 0.65);
  border-bottom: 1px solid hsla(0, 0%, 86.3%, 0.26);
  box-shadow: 0 0 2px transparent !important;
}
.react-jinke-music-player-main.light-theme .audio-item:active,
.react-jinke-music-player-main.light-theme .audio-item:hover {
  background-color: #fafafa !important;
}
.react-jinke-music-player-main.light-theme .audio-item:active svg,
.react-jinke-music-player-main.light-theme .audio-item:hover svg {
  color: #31c27c;
}
.react-jinke-music-player-main.light-theme .audio-item.playing {
  background-color: #fafafa !important;
}
.react-jinke-music-player-main.light-theme .audio-item.playing svg {
  color: #31c27c;
}
.react-jinke-music-player-main.light-theme .audio-item.playing .player-singer {
  color: #31c27c !important;
}
.react-jinke-music-player-main.light-theme .audio-item .player-singer {
  color: hsla(0, 0%, 63.5%, 0.45) !important;
}
.react-jinke-music-player-main.light-theme .react-jinke-music-player-mobile {
  background-color: #fff;
  color: #444;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-cover {
  border: 5px solid transparent;
  box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.2);
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile
  .current-time,
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile
  .duration {
  color: #444;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile
  .rc-slider-rail {
  background-color: #e9e9e9;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-operation
  svg {
  color: #444;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-tip
  svg {
  color: #fff !important;
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-singer-name {
  color: #444;
  transition: color 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-singer-name:after,
.react-jinke-music-player-main.light-theme
  .react-jinke-music-player-mobile-singer-name:before {
  background-color: #444;
}
.react-jinke-music-player-main.light-theme .play-mode-title {
  background-color: #fff;
  color: #31c27c;
}
.react-jinke-music-player {
  height: 80px;
  position: fixed;
  width: 80px;
  z-index: 999;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player {
    height: 60px;
    width: 60px;
  }
}
.react-jinke-music-player:focus {
  outline: none;
}
.react-jinke-music-player .music-player {
  cursor: pointer;
  height: 80px;
  position: relative;
  width: 80px;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player .music-player {
    height: 60px;
    width: 60px;
  }
}
.react-jinke-music-player .music-player:focus {
  outline: none;
}
.react-jinke-music-player .music-player-audio {
  display: none !important;
}
.react-jinke-music-player .music-player .destroy-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player .music-player .destroy-btn {
    right: -3px;
  }
}
.react-jinke-music-player .music-player-controller {
  align-items: center;
  background-color: #e6e6e6;
  background-repeat: no-repeat;
  background-size: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  color: #31c27c;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  height: 80px;
  justify-content: center;
  padding: 10px;
  position: fixed;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 80px;
  z-index: 99;
}
.react-jinke-music-player .music-player-controller:focus {
  outline: none;
}
.react-jinke-music-player .music-player-controller.music-player-playing:before {
  -webkit-animation: scale 5s linear infinite;
          animation: scale 5s linear infinite;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  border-radius: 50%;
  content: "";
  cursor: pointer;
  height: 80px;
  position: fixed;
  width: 80px;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player .music-player-controller {
    height: 60px;
    width: 60px;
  }
  .react-jinke-music-player
    .music-player-controller.music-player-playing:before {
    height: 60px;
    width: 60px;
  }
}
.react-jinke-music-player .music-player-controller i {
  font-size: 28px;
}
.react-jinke-music-player .music-player-controller:active {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}
.react-jinke-music-player .music-player-controller:hover {
  font-size: 16px;
}
.react-jinke-music-player
  .music-player-controller:hover
  .music-player-controller-setting {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.react-jinke-music-player .music-player-controller .controller-title {
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player .music-player-controller i {
    font-size: 20px;
  }
  .react-jinke-music-player .music-player-controller:active,
  .react-jinke-music-player .music-player-controller:hover {
    font-size: 12px;
  }
  .react-jinke-music-player
    .music-player-controller:active
    .music-player-controller-setting,
  .react-jinke-music-player
    .music-player-controller:hover
    .music-player-controller-setting {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.react-jinke-music-player
  .music-player-controller
  .music-player-controller-setting {
  align-items: center;
  background: rgba(49, 194, 124, 0.3);
  border-radius: 50%;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 0.4s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  width: 100%;
}
.react-jinke-music-player .audio-circle-process-bar {
  stroke-width: 3px;
  stroke-linejoin: round;
  -webkit-animation: scaleTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
          animation: scaleTo 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
  height: 80px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: -80px;
  width: 80px;
  z-index: 100;
}
.react-jinke-music-player .audio-circle-process-bar circle[class="bg"] {
  stroke: #fff;
}
.react-jinke-music-player .audio-circle-process-bar circle[class="stroke"] {
  stroke: #31c27c;
}
.react-jinke-music-player .audio-circle-process-bar,
.react-jinke-music-player .audio-circle-process-bar circle {
  -webkit-transform: matrix(0, -1, 1, 0, 0, 80);
          transform: matrix(0, -1, 1, 0, 0, 80);
  transition: stroke-dasharray 0.35s cubic-bezier(0.43, -0.1, 0.16, 1.1);
}
@media screen and (max-width: 767px) {
  .react-jinke-music-player .audio-circle-process-bar,
  .react-jinke-music-player .audio-circle-process-bar circle {
    -webkit-transform: matrix(0, -1, 1, 0, 0, 60);
            transform: matrix(0, -1, 1, 0, 0, 60);
  }
}

