.group-page {
  padding-top: 0;
}

.groups .featured-recordings-item img {
  width: 150px;
  height: 150px;
}

div.DraftEditor-root {
  border: 1px solid rgb(131, 131, 131);
  background-color: #ffffff;
  height: 200px;
  width: 300px;
  overflow-y: auto;
}

.group-page {
  &.page-wrapper {
    padding-top: 0;
  }

  .page-title {
    margin-bottom: 0;
  }
}

.group-banner-wrapper {
  position: relative;
  width: 100%;
  padding: 50px 0;
  overflow: hidden;
  z-index: 0;
  color: #fff;
}

.group-banner-background-false {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }
}

.group-banner-background-true {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.group-banner-background-false::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.group-banner-background-true::after {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.group-banner-content {
  display: flex;
  align-items: center;
  font-size: 1.2em;
}

.group-banner-image {
  position: relative;
  width: 200px;
  height: 200px;
  border: 3px solid #fff;
  border-radius: 50%;
  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }
}

.group-banner-title {
  flex: 1;
  margin-left: 40px;
}

.group-banner-cta {
  text-align: center;
}

.group-banner-cta button {
  margin-top: 10px;
}

.group-header {
  display: flex;
  position: relative;
}

.group-container {
  display: flex;
  position: relative;

  .group-content {
    display: block;
    flex: 3;
    margin-right: 40px;
  }

  .group-sidebar {
    flex: 2;

    .comments {
      margin-top: 40px;
      padding-top: 0;
      border-top: 0;
    }

    .member-list .member-list-item {
      padding: 10px;
      background-color: #fff;
    }

    .member-list-item a {
      color: #26466d;
    }

    .member-list-item a:hover {
      color: #223e61;
    }
  }
}

.group-manage-modal {
  /* set the width of the modal */
  width: 70%;
  /* center the modal horizontally */
}

/* increase the padding and margin of the elements inside the modal */
.group-manage-modal .space-sm {
  margin-bottom: 1rem;
}

/* align the elements inside the modal to the left */
.group-manage-modal label,
.group-manage-modal input,
.group-manage-modal textarea,
.group-manage-modal .member-field {
  text-align: left;
}

/* align the group picture and cover photo to the center */
.group-manage-modal .main-photo img,
.group-manage-modal img {
  display: block;
  margin: 0 auto;
}


.group-manage-modal label {
  font-weight: bold;
}

@media (max-width: 767px) {
    .group-banner-image {
        position: absolute;
        width: 75px;
        height: 75px;
        border: 3px solid #fff;
        border-radius: 50%;
        overflow: hidden;
      
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: translate(-50%, -50%);
        }
      }
      .group-banner-content {
        // display: flex;
        // align-items: center;
        // font-size: 0.5em;
      }

      .group-page {
        
      }
      .group-manage-modal {
        width: 95%;
      }
}
