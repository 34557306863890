/* discussion Banner Style */
.discussion-banner {
    display: block;
    margin-bottom: 40px;
    align-items: center;
    text-align: left;

    .discussion-banner-image {
        width: 120px;
        background-color: #efefef;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .discussion-banner-content {
        margin-left: 40px;

        h2 {
            color: #26466d;
            text-transform: uppercase;
            letter-spacing: 2px;
            display: flex;
            align-items: center;

            .play-button-discussion {
                margin-left: 20px;
            }
        }
    }
}

.discussion-container {
    text-align: left;
}

.discussion-intro {
    display: flex;
    list-style: none;
    margin: 20px 0 0;
    padding: 0;
    line-height: 1.2;

    li+li {
        margin-left: 15px;
        padding-left: 15px;
        border-left: 1px solid #999;
    }
}

.discussion-background {
    height: 230px;
    width: auto;
    object-fit: contain;
}

.discussion-title {
    color: #26466d;
    font-family: "Avenir Next";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 27px;
}

.discussion-user {
    color: #26466d;
    font-family: "Avenir Next";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.5px;
    line-height: 19px;
    text-align: center;
}

.play-number {
    color: #26466d;
    font-family: "Avenir Next";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.67px;
    line-height: 27px;
    text-align: center;
}

.total-plays {
    color: #26466d;
    font-family: "Avenir Next";
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    text-align: center;
}

.discussion-author-profile-img {
    padding: 3px;
    width: 150px;
    height: 150px;
}

.recitation-author-profile-img {
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.discussion-author-profile-img-inner {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.progress {
    height: 5px;
}

.progress-bar {
    background-color: #000;
    height: 5px;
}

.discussion-text {
    height: 470px;
    overflow: scroll;
}

.display-linebreak {
    white-space: pre-line;
}

.discussion-content {
    display: flex;
    position: relative;
}

.discussion-detail {
    flex: 2;
    margin-right: 40px;
}

.discussion-sidebar {
    flex: 1;
}

.discussion-script {
    .ql-editor {
        padding: 0;
    }
    .ql-container {
        border: none !important;  
    }
    margin: 20px 0;
    white-space: pre-line;
}

.discussion-actions {
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: #212529;
    margin-bottom: 20px;
    background-color: #f8f9fa;
    padding: 10px;
    button {
        display: flex;
        align-items: center;
        margin-right: 60px;
        padding: 0;
        border: 0;
        background: none;
        line-height: 1;
    }
    a {
        color: #212529;
        &:hover {
            color: #223e61;
        }
    }
    img, svg {
        display: inline-block;
        margin-right: 10px;
    }
}

.discussion-actions-list {
    li {
        padding: 0;
    }
    .discussion-action-btn {
        display: flex;
        width: 100%;
        align-items: center;
        background: none;
        border: 0;
        color: #212529;
        font-size: 16px;
        line-height: 1;
        margin: 0;
        padding: 15px 20px;
        &:hover {
            background-color: #f8f9fa;
        }
        img, i {
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 15px;
            font-size: 22px;
            line-height: 0.7;
            color: #26466d;
        }
        p {
            margin-bottom: 0;
        }
    }
}

.button-dark-blue {
    color: #26466d;
    background-color: #fffaf0;
}

.audio-player {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.play-button-discussion {
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
    background-color: #31c27c;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;

    &:hover {
        background-color: #223e61;
    }

    svg {
        height: 25px;
        margin-left: 5px;
        color: #fff;
    }

    &.small {
        width: 20px;
        height: 20px;

        svg {
            height: 20px;
            margin-left: 2px;
        }
    }
}

.discussion-more-readings {
    .section-title {
        margin-bottom: 20px;
    }
    
    .item-info {
        h5 {
            display: flex;
            justify-content: space-between;
            font-size: 1.1em;
            margin-bottom: 10px;

            a {
                color: #26466d;

                &:hover {
                    color: #223e61;
                }
            }
        }

        p {
            font-size: 11px;

            b {
                color: #26466d;
            }

            a {
                font-weight: 400;
                color: #333;

                &:hover {
                    color: #26466d;
                }
            }
        }
    }

    .item-cta {
        font-size: 11px;
    }
}

.rec-action-buttons {
    color: #26466d;
    font-family: "Avenir Next";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.75px;
    line-height: 12px;
    text-align: center;
    padding-top: 5px;
}

.hover-link {
    color: black !important;
}

.hover-link:hover {
    color: blue !important;
}

.poem-script {
    .title {
        font-size: xx-large;
    }
    .ql-editor {
        padding: 0;
    }
    .ql-snow .ql-editor h1 {
        font-size: 3em;
    }
    .ql-snow .ql-editor h3 {
        font-size: 1.3em;
    }
    .ql-container {
        border: none !important;  
    }
    margin: 20px 0;
    white-space: pre-line;
}