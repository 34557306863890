/* Poem Banner Style */
.poem-banner {
  position: relative;
  width: 100%;
  padding: 40px 0;
  overflow: hidden;
  z-index: 0;
  color: #fff;

  // .poem-banner-image {
  //     width: 120px;
  //     background-color: #efefef;
  //     margin-bottom: 20px;

  //     img {
  //         width: 100%;
  //         height: 100%;
  //         object-fit: cover;
  //     }
  // }

  // .poem-banner-content {
  //     margin-left: 40px;

  //     h2 {
  //         color: #26466d;
  //         text-transform: uppercase;
  //         letter-spacing: 2px;
  //         display: flex;
  //         align-items: center;

  //         .play-button {
  //             margin-left: 20px;
  //         }
  //     }
  // }
}

.poem-page.page-wrapper {
  margin-top: 0;
  padding-top: 0;
}

.poem-banner-wrapper {
  position: relative;
  width: 100%;
  padding: 150px 0;
  overflow: hidden;
  z-index: 0;
  color: #fff;
  margin-bottom: 20px;
}

.poem-banner-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }
}

.poem-banner-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.poem-banner-content {
  display: flex;
  align-items: center;
  font-size: 1.2em;
}

.poem-banner-title {
  flex: auto;
  margin-left: 40px;
}

.poem-banner-subtitle {
  font-size: 17px;
}

.poem-banner-image {
  position: relative;
  width: 100px; /* Set width in percentage */
  height: 100px;
  // padding-bottom: 10%; /* Set padding-bottom equal to the width percentage to maintain aspect ratio */
  border: 3px solid #fff;
  border-radius: 50%;
  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: inline;
    transform: translate(-50%, -50%);
  }
}

/* Add media queries for different screen sizes */

.poem-container {
  text-align: left;
}

.poem-intro {
  display: flex;
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
  line-height: 1.2;

  li + li {
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid #999;
  }
}

.poem-background {
  height: 230px;
  width: auto;
  object-fit: contain;
}

.poem-title {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 27px;
}

.poem-user {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 19px;
  text-align: center;
}

.play-number {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1.67px;
  line-height: 27px;
  text-align: center;
}

.total-plays {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
}

.poem-author-profile-img {
  padding: 3px;
  width: 150px;
  height: 150px;
}

.recitation-author-profile-img {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.poem-author-profile-img-inner {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.progress {
  height: 5px;
}

.progress-bar {
  background-color: #000;
  height: 5px;
}

.poem-text {
  height: 470px;
  overflow: scroll;
}

.display-linebreak {
  white-space: pre-line;
}

.poem-content {
  display: flex;
  position: relative;
}

.poem-detail {
  flex: 2;
  margin-right: 40px;
}

.poem-sidebar {
  flex: 1;
}

.poem-script {
  .ql-editor {
    padding: 0;
  }
  .ql-container {
    border: none !important;
  }
  p {
    font-size: medium;
  }
  h1 {
    font-size: xx-large;
  }
  margin: 20px 0;
  white-space: pre-line;
}

.poem-actions {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #212529;
  margin-bottom: 20px;
  background-color: #f8f9fa;
  padding: 10px;
  button {
    display: flex;
    align-items: center;
    margin-right: 60px;
    padding: 0;
    border: 0;
    background: none;
    line-height: 1;
  }
  a {
    color: #212529;
    &:hover {
      color: #223e61;
    }
  }
  img,
  svg {
    display: inline-block;
    margin-right: 10px;
  }
}

.poem-actions-list {
  li {
    padding: 0;
  }
  .poem-action-btn {
    display: flex;
    width: 100%;
    align-items: center;
    background: none;
    border: 0;
    color: #212529;
    font-size: 16px;
    line-height: 1;
    margin: 0;
    padding: 15px 20px;
    &:hover {
      background-color: #f8f9fa;
    }
    img,
    i {
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 15px;
      font-size: 22px;
      line-height: 0.7;
      color: #26466d;
    }
    p {
      margin-bottom: 0;
    }
  }
}

.button-dark-blue {
  color: #26466d;
  background-color: #fffaf0;
}

.play-button-poem {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  // increase size of icon
  width: 100% !important;
  height: 100% !important;
  background-color: #31c27c;
  border-radius: 50%;
  color: #fff;

  transform: scale(2);

  &:hover {
    background-color: #223e61;
  }

  // &.small {
  //     width: 20px;
  //     height: 20px;

  //     svg {
  //         height: 20px;
  //         margin-left: 2px;
  //     }
  // }
}

.poem-more-readings {
  .section-title {
    margin-bottom: 20px;
  }

  .item-info {
    h5 {
      display: flex;
      justify-content: space-between;
      font-size: 1.1em;
      margin-bottom: 10px;

      a {
        color: #26466d;

        &:hover {
          color: #223e61;
        }
      }
    }

    p {
      font-size: 11px;

      b {
        color: #26466d;
      }

      a {
        font-weight: 400;
        color: #333;

        &:hover {
          color: #26466d;
        }
      }
    }
  }

  .item-cta {
    font-size: 11px;
  }
}

.rec-action-buttons {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.75px;
  line-height: 12px;
  text-align: center;
  padding-top: 5px;
}

.hover-link {
  color: black !important;
}

.hover-link:hover {
  color: blue !important;
}

.no-border {
  border: none !important;
}

@media (max-width: 767px) {
  /* Change the size for mobile devices */
  .poem-banner-image {
    width: 50px; /* Set width in percentage */
    height: 50px;
  }
  .poem-banner-subtitle {
    font-size: 10px;
  }
  .poem-banner-title h2 {
    font-size: 20px;
  }
  .poem-page {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .poem-banner-content {
    display: flex;
    align-items: start;
    font-size: 1em;
  }
}
