.playlist-banner .wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.playlist-banner-body {
  display: flex;
  width: calc(100% - 250px);
  justify-content: space-between;
}

.playlist-covers {
  position: relative;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  list-style: none;
  font-size: 0;
  background-color: #fff;
  border-radius: 100px;
  overflow: hidden;

  .playlist-cover {
    position: absolute;
    width: calc(50% - 1px);
    height: calc(50% - 1px);
    background-color: #efefef;
    overflow: hidden;

    &:nth-child(1) {
      top: 0;
      left: 0;
    }
    &:nth-child(2) {
      top: 0;
      right: 0;
    }
    &:nth-child(3) {
      bottom: 0;
      left: 0;
    }
    &:nth-child(4) {
      bottom: 0;
      right: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.playlist-info {
  width: calc(100% - 250px);
}

.playlist-info {
  color: #777;
}

.playlist-info h1 {
  color: #26466d;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.playlist-add {
  display: flex;
  width: calc(100%);
  justify-content: right;
  padding: 10px;
}

.modal-buttons {
  padding-right: 10px;
  overflow: hidden;
  width: auto;
  display: inline;
}

.buttons {
  float: right;
  margin-left: 0;
}
