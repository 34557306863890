/**
  Global Style
**/
html,
body {
    background-color: #fff;
}

a:hover {
    text-decoration: none;
}

img {
    max-width: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;
    overflow-x: auto;
    word-wrap: break-word;
    height: 1; /* Set the height of the App to 100% of the viewport height */
    width: 100vw;
}
@media (max-width: 768px) {
    .App {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      flex-flow: column;
      word-wrap: break-word;
    }
  } 

.poem-script {
    .ql-editor {
        padding: 0px;
    }
    .ql-container {
        border: none !important;  
    }
    p {
        font-size: medium;
    }
    margin: 20px 0;
    white-space: pre-line;
}


.music-player {
    .music-player-panel {
        padding-left: 80px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important;
        z-index: 1000;
        left: 0;
        bottom: 0;
    }

    .music-player-lyric {
        padding: 10px;
        cursor: pointer !important;
        bottom: 80px !important;
        background-color: #223e61 !important;
    }

    .audio-lists-panel {
        right: 0;
    }

    .react-jinke-music-player {
        z-index: 1000;
        top: auto !important;
        left: auto !important;
        right: 0 !important;
        bottom: 0 !important;

        .music-player-controller {
            width: 60px;
            height: 60px;
            .music-player-controller-setting {
                font-size: 14px;
                background: rgba(49, 194, 124, .8);
            }

        }
    }
}

/**
  Custom Style
**/
.interior-page h2,
.interior-page h3,
.interior-page h4,
.interior-page h5,
.interior-page ul,
.interior-page ol,
.interior-page p {
    margin-bottom: 20px;
}

.interior-page h6 {
    color: #26466d;
    font-size: 1.15em;
    margin-bottom: 10px;
}

.center {
    text-align: center;
}

.page-main {
    display: flex;
    flex-wrap: wrap;
}

.page-wrapper {
    margin: 66px 0 80px 80px;
    padding: 40px 0;
}

.lyrics-text {
    font-family: "Avenir Next";
}

.lyrics-text-title {
    font-size: xx-large;
    
}

.page-title {
    color: #26466d;
    font-family: "Avenir Next";
    font-size: 1.6em;
    font-weight: 600;
    text-align: center;
    letter-spacing: 2.14px;
    line-height: 27px;
    margin-bottom: 40px;
}

.page-title-sm {
    color: #26466d;
    font-family: "Avenir Next";
    font-size: 1.3em;
    font-weight: 600;
    text-align: center;
    letter-spacing: 2.14px;
    line-height: 27px;
    margin-bottom: 40px;
}

.section-title {
    color: #26466d;
    font-weight: 500;
    font-size: 1.2em;
    letter-spacing: 2.14px;
}

.space {
    margin-top: 60px;
    margin-bottom: 60px;
}

.space-md {
    margin-top: 40px;
    margin-bottom: 40px;
}

.space-sm {
    margin-top: 20px;
    margin-bottom: 20px;
}

.top-space {
    margin-top: 60px;
}

.top-space-md {
    margin-top: 40px;
}

.top-space-sm {
    margin-top: 20px;
}

.left-space {
    margin-left: 40px;
}

.left-space-md {
    margin-left: 20px;
}

.left-space-sm {
    margin-left: 10px;
}

.right-space {
    margin-right: 40px;
}

.right-space-md {
    margin-right: 20px;
}

.right-space-sm {
    margin-right: 10px;
}

.flex {
    display: flex;
    align-items: center;
}

.wrap {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.button {
    display: inline-block;
    height: 40px;
    padding: 0 25px;
    border-radius: 5px;
    font-family: "Avenir Next";
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.1px;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    border: 1px;
    transition: 0.2s;
    background-color: #ddd;
    &.small {
        height: 34px;
        line-height: 34px;
        font-size: 11px;
    }
    &.primary {
        background-color: #26466d;
        color: #fff;
        &:hover {
            background-color: #223e61;
        }
    }
    &:hover {
        text-decoration: none;
        cursor: pointer;
        background-color: #ccc;
    }
    &.danger {
        background-color: #e74c3c;
        color: #fff;
        &:hover {
            background-color: #c0392b;
        }
    }
    &.warning {
        background-color: #f39c12;
        color: #fff;
        &:hover {
            background-color: #d35400;
        }
    }
}

button.edit-button {
    background: none;
    padding: 0;
    border: 0;
    color: #282c34;
    &::after {
        display: none;
    }
}

.featured-poet-image {
    border-radius: 10px;
    border: 1px solid #c1c1c1;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #cdcdcd;
}

.recent-uploads-wrapper,
.featured-poet-wrapper {
    height: 540px;
    overflow-y: scroll;
}

.content-list {
    width: 100%;
    padding: 0;
    margin-bottom: 0;
    list-style: none;
}

.content-list-item {
    display: flex;
    align-items: center;

    .item-image {
        position: relative;
        width: 80px;
        height: 80px;
        background-color: #c1c1c1;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: translate(-50%, -50%);
        }
    }

    .item-info {
        flex: 1;
        margin-left: 20px;

        p {
            margin-top: 5px;
            margin-bottom: 0;
        }
    }
}

.reading-list-item+.reading-list-item {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #fff;
}

.item-cta {
    display: flex;
    button {
        margin-left: 20px;
        font-size: 18px;
        margin-right: 0;
        padding: 0;
        border: 0 !important;
        background: none !important;
        svg {
            margin: 0 !important;
            color: #223e61;
        }
        &:hover {
            svg {
                color: #26466d;
            }
        }
    }
}

.group-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.group-list-item {
    padding: 10px;
    background-color: #fff;

    a {
        color: #26466d;
        &:hover {
            color: #223e61;
        }
    }

    .item-image {
        background-color: #c1c1c1;
    }
}

.group-manage-modal {
    img {
        width: 100%;
    }

    .main-photo {
        position: relative;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .member-field {
        display: flex;

        .button {
            height: 38px;
            line-height: 38px;
        }
    }
}

.member-list-item+.member-list-item {
    margin-top: 20px;
    padding-top: 20px;
}

.playlist-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.playlist-list-item {
    width: 49%;

    &:nth-child(n + 4) {
        margin-top: 20px;
        padding-top: 20px;
    }
}



.tab-container {
    margin-top: 40px;
    background-color: #f4f4f4;
    border-radius: 5px;
    padding: 20px;
}

.craousel-control {
    cursor: pointer;
}

.tab-control {
    position: relative;
    margin-top: 40px;

    .nav-tabs .nav-item a {
        cursor: pointer;
    }

    .button {
        position: relative;
        background-color: #223e61;
        color: white;
    }
}

.comments {
    margin-top: 80px;
    padding-top: 40px;
    border-top: 1px solid #ccc;

    h4 {
        margin-bottom: 20px;
    }
    
    .comment-form {
        overflow: hidden;
        margin-bottom: 20px;

        .comment-fields {
            display: flex;
            flex-wrap: nowrap;
        }

        .button {
            float: right;
            margin-top: 10px;
        }
    }

    .comment-list {
        padding: 0;
        list-style: none;
        overflow: auto;

        li {
            list-style: none;

            &>div {
                display: table-cell;
            }
        }
    }
}

.commenter-image {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    float: left;

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }
}

.commenter-image-abs {
    position: absolute;
}

.comment-text-bold {
    font-weight: 500;
}

.comment-text p {
    margin: 0;
}

.sub-text {
    font-size: 11px;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
