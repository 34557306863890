.search-subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-subtitle {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.search-subtitle a {
  font-size: 0.7em;
}

.search-result-section {
  margin-top: 60px;
}

.search-result-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.search-result-item {
  display: flex;
}

.search-result-profile {
  text-align: center;
}

.search-result-profile .profile-image {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto 20px;
  overflow: hidden;
  border-radius: 50%;
  font-size: 0;
}

.search-result-profile .profile-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.result-card-list {
  display: flex;
  text-align: left;
  flex-wrap: wrap;
}

.result-card-item {
  width: 23.5%;
  margin-left: 2%;
}

.result-card-item:nth-child(4n + 1) {
  margin-left: 0;
}

.result-card-item:nth-child(n + 5) {
  margin-top: 20px;
}

.item-image {
  max-width: 150px;
}

.item-info {
  margin-left: 40px;
  text-align: left;
  font-size: 0.8em;
}

.item-info h4 {
  font-size: 1.4em;
}

.item-info span {
  display: inline-block;
  margin-right: 5px;
}

.item-info h5 {
  font-size: 1.2em;
}

.search-result-item img {
  max-width: 100%;
}

.search-result-item + .search-result-item {
  margin-top: 20px;
}
