.upload-page .container {
    max-width: 800px;
}

.upload-button-group {
    margin-top: 40px;
}

.upload-button-group .upload-button {
    display: inline-block;
    margin: 0 15px;
}

.upload-box {
    padding: 20px;
}

.recitation-icon {
    width: 49px;
}

.upload-img {
    border-radius: 5px;
    box-shadow: 0 3px 4px 0 #acacac;
    margin-bottom: 20px;
}

.user-name {
    color: #26466d;
    font-family: "Avenir Next";
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.5px;
    line-height: 19px;
    text-align: center;
}

.details-form input {
    margin-bottom: 10%;
}

.uppercase {
    text-transform: uppercase;
}

.form-fields input[type="text"] {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.upload-page .form-fields {
    margin-top: 40px;
}

.upload-page .form-field+.form-field {
    margin-top: 20px;
}

.upload-page .form-action {
    margin-top: 40px;
}

.upload-image {
    width: 100%;
    margin-bottom: 20px;
}

.upload-image img {
    max-width: 100%;
}

.upload-image button {
    margin-top: 20px;
}

.chosen-image {
    position: relative;
    background-color: #fff;
    height: 230px;
    margin: auto;
    overflow: hidden;
}

.chosen-image::before {
    position: absolute;
    width: 100%;
    height: 100%;
    border-top: 2px solid #ddd;
    top: 50%;
    left: 0;
    transform: rotate(45deg);
}

.details-form {
    width: 50%;
    margin: auto;
    padding-top: 25px;
    padding-bottom: 25px;
}

.half-form {
    width: 49%;
    margin-left: 1%;
    display: inline;
}

.legal-text {
    color: #828282;
    font-family: "Avenir Next";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.29px;
    line-height: 16px;
    text-align: left;
}

.file-picker div {
    display: inline;
}

.record-wrapper {
    height: calc(100vh - 146px);
    padding: 0;
}

.record-wrapper .wrap {
    width: 100%;
    height: 100%;
    padding: 20px;
}

.record-container {
    height: calc(100% - 116px);
    display: flex;
    align-items: center;
}

.record-container .record-box {
    /* flex: 1; */
}

.record-container .record-script {
    flex: 1;
    margin-left: 80px;
}

.upload-control {

    div.DraftEditor-root {
        border: 0.5px solid #838383;
        background-color: #ffffff;
        height: 200px;
        width: 90%;
        overflow-y: auto;
        padding-left: 10px;
        padding-right: 10px;
    }

    position: relative;
    width: 100%;
    height: 100%;
    margin-left: 40px;

    .record-tab {
        .nav-tabs {
            .nav-link {
                cursor: pointer;
                font-size: 14px;
                padding: 6px 15px;
            }
        }
    }

    .tab-content {
        width: 100%;
        margin-top: 20px;
    }

    textarea {
        position: absolute;
        width: 100%;
        height: calc(100% - 80px);
        left: 0;
        overflow-y: auto;
        padding: 20px;
        text-align: left;
        background-color: #fff;
        border: 0;
        border-radius: 3px;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    }

   
}

.spaced-item {
    margin-top: 20px;
}

.search-poem {
    padding-bottom: 40px;

    form {
        display: flex;
        margin-top: 20px;
        button {
            margin-left: 10px;
        }
    }

    ul {
        max-height: 300px;
        background-color: #fff;
        overflow-y: auto;
        border-radius: 0;

        li {
            list-style: none;
            font-size: 0.9em;
            
            &+li {
                border-top: 1px solid #eee;
            }

            button {
                display: block;
                width: 100%;
                text-align: left;
                padding: 10px 20px;
                color: #000;
                background: none;
                border: 0;

                &:hover {
                    background-color: #f8f9fa;
                }
            }

            span {
                display: block;
                margin-top: 5px;
                font-size: 0.8em;
                color: #555;
            }
        }
    }

    .search-list {
        margin-top: 20px;
    }
}

#form_2 {
    form {
        cursor: pointer;

        .ginput_container_fileupload {
            position: relative;
            display: block;
            width: 100%;
            background-color: #F7F7F7;

            .upload-upper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px;
                font-size: 0;

                span {
                    margin-bottom: 0;
                }

                .upload-text {
                    width: 50%;
                    display: inline-block;
                    vertical-align: middle;
                    color: #000;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-size: 13px;

                    span {
                        color: #BABABA;
                    }
                }

                .upload-btn {
                    display: inline-block;
                    vertical-align: middle;
                    width: 120px;
                    height: 40px;
                    line-height: 40px;
                    background-color: #26466d;
                    color: #fff;
                    font-size: 13px;
                    letter-spacing: 1px;
                    text-align: center;
                    text-transform: uppercase;
                }
            }

            input[type=file] {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 0;
                padding-top: 126px;
                overflow: hidden;
            }

            .upload-filename {
                display: block;
                width: 100%;
                height: 58px;
                line-height: 58px;
                padding: 0 10px;
                background-color: rgba(#D8D8D8, 0.4);
                font-size: 13px;
                color: #000;
                box-sizing: border-box;
            }
        }

        &:hover {
            .upload-upper {
                .upload-btn {
                    background-color: #26466d;
                }
            }
        }
    }
}

.continue-button {
    background: none;
    border: none;
}

.hide-default-upload {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: 1px;
    height: 1px;
}

.custom-input {
    display: unset;
    z-index: 99;
}

.upload-field {
    margin-top: 20px;
}

/* Styles for Tags */
div.ReactTags__tagInput {
    border-radius: 2px;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    width: 100%;
}

.ReactTags__selected {
    text-align: left;
}

div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px 10px;
    margin-bottom: 5px;
    cursor: move;
    border-radius: 2px;
}

div.ReactTags__selected span.ReactTags__tag+span.ReactTags__tag {
    margin-left: 10px;
}

div.ReactTags__selected span.ReactTags__tag button {
    border: 0;
    padding: 0;
    margin-left: 5px;
    font-size: 14px;
}

div.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

div.ReactTags__suggestions {
    position: absolute;
}

div.ReactTags__suggestions ul {
    list-style-type: none;
    list-style: none;
    text-align: left;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
    padding: 0;
}

div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}

div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}
