.homepage {
    margin-left: 100px;
    margin-right: 20px;
}

.streaming-content {}

.content-section+.content-section {
    margin-top: 40px;
}

.main-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

.content-main {
    width: 62%;
}

.content-sub {
    width: 35%;
    margin-left: 3%;
}

.content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.content-section {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 5px;
}

.featured-content {
    .card {
        border: 0;
    }
    .featured-recording-img {
        background-color: #c1c1c1;
    }
}

.playlist-list {
    justify-content: start;
}

.popular-playlists {
    h5 {
        padding: 0;
    }

    a {
        display: block;

        color: #26466d;

        &:hover {
            color: #223e61;
        }
    }

    .playlist-images {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        padding-bottom: 100%;
        list-style: none;
        font-size: 0;
        background-color: #fff;
        
        .playlist-image {
            position: absolute;
            width: calc(50% - 3px);
            height: calc(50% - 3px);
            background-color: #c1c1c1;
            border-radius: 4px;
            overflow: hidden;

            &:nth-child(1) {
                top: 2px;
                left: 2px;
            }

            &:nth-child(2) {
                top: 2px;
                right: 2px;
            }

            &:nth-child(3) {
                bottom: 2px;
                left: 2px;
            }

            &:nth-child(4) {
                bottom: 2px;
                right: 2px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

    }
}

.alice-carousel__dots {
    margin-top: 15px;
}

.recent-upload .upload-list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1em;

    .list-item { 
        h5 {
            font-size: 1.1em;

            a {
                color: #26466d;

                &:hover {
                    color: #223e61;
                }
            }
            p {
                font-size: 11px;
                b {
                    color: #26466d;
                }
                a {
                    font-size: 11px;
                    font-weight: 400;
                    color: #333;

                    &:hover {
                        color: #26466d;
                    }
                }
            }
        }

        .item-cta {
            font-size: 11px;
        }

        &+.list-item {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid #fff;
        }
    }
}

.featured-poet-heading {
    color: #26466d;
    font-family: "Avenir Next";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 25px;
}

.poet-name {
    color: #26466d;
    font-family: "Avenir Next";
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1.88px;
    line-height: 20px;
}

.biography {
    color: #26466d;
    font-family: "Avenir Next";
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.29px;
    line-height: 16px;
}

.featured-text {
    color: #26466d;
    font-family: "Avenir Next";
    font-size: 13px;
    letter-spacing: 1.08px;
    line-height: 18px;
}

.featured-bar {
    background-color: #26466d;
}

.white {
    color: white;
}

.featured-head,
.featured-head:hover {
    color: #26466d;
    font-family: "Avenir Next";
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    text-decoration: none;
}

.featured-poet,
.featured-poet:hover {
    color: #26466d;
    font-family: "Avenir Next";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
}

.recently-uploaded {
    color: #26466d;
    font-family: "Avenir Next";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2.57px;
    line-height: 25px;
}

.content-section {
    position: relative;
}
