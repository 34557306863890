.project-script {
    .ql-editor {
      padding: 0;
    }
    .ql-container {
      border: none !important;
    }
    p {
      font-size: medium;
    }
    li {
        font-size: medium;
    }

    white-space: normal;
  }


  .project-page.page-wrapper {
    margin-top: 0;
    padding-top: 0;
  }

  .project-page {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }