/* General CSS Settings Start */
@font-face {
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: 400;
  src: url("./static/fonts/AvenirNextLTPro-Regular.otf") format("otf");
  color: #313a40;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  font-family: "Avenir Next" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-success {
  background-color: #4ca746 !important;
  color: #fff;
}

.bg-haze {
  background-color: #fffaf0 !important;
}

.bg-loading {
  background-color: #f9e6c7 !important;
}

.bg-red {
  background-color: #cf3c3f !important;
  color: #fff;
}

.bg-blue {
  background-color: #26466d;
}

.bg-dark-grey {
  background-color: #3e4755 !important;
  color: #fff;
}

.bg-grey {
  background-color: #fafafa !important;
}
.bg-lt-grey {
  background-color: #dedede !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-none {
  background: transparent !important;
}

.bg-dark-blue {
  background-color: #26466d !important;
  color: #fffaf0;
}

.font-black {
  color: #000 !important;
}

.font-grey {
  color: #bababa !important;
}

.font-dark-grey {
  color: #313a40 !important;
}

.font-green {
  color: #81cac4 !important;
}

.font-red {
  color: #ff0000 !important;
}

.font-white {
  color: #fff !important;
}

.fw-bold {
  font-weight: 600;
}
.fa-sm {
  font-size: 0.75em !important;
}

.fa-1-5x {
  font-size: 1.5em;
}
/* General CSS Settings End */

/* Pre Login Home Carousel CSS Settings start */
.carousel-item img {
  padding: 1px;
  max-height: 600px;
  display: block !important;
}

.carousel-item .carousel-caption {
  bottom: 40%;
}

.carousel-caption h3 {
  font-size: 3rem;
  color: #000;
}

.carousel-caption p {
  font-size: 2rem;
  color: #000;
  font-weight: 500;
}

/* Carousel CSS Settings end */

/* Profile CSS Settings Start */
.profile-banner {
  height: 300px;
  background-color: #cecece;
}

.profile-background-image {
  max-width: 100%;
  max-height: 100%;
}

.profile-banner .profile-banner-wrapper {
  overflow: hidden;
  height: 100%;
}

.profile-banner .profile-banner-wrapper img {
  -webkit-transform: scale(3);
  -moz-transform: scale(3);
  -ms-transform: scale(3);
  -o-transform: scale(3);
  transform: scale(3);
}

.profile-banner-2 {
  height: 50px;
  background-color: #2f3a41;
}
.main-left {
  height: 640px;
  background-color: #ffffff;
}

.profile-img .profile-img-wrapper {
  overflow: hidden;
}

.profile-img .profile-img-wrapper img {
  /*-webkit-transform: scale(1.5);*/
  /*-moz-transform: scale(1.5);*/
  /*-ms-transform: scale(1.5);*/
  /*-o-transform: scale(1.5);*/
  /*transform: scale(1.5);*/
}

.fav-container {
  height: 640px;
  background-color: #ffffff;
  overflow-y: scroll;
}

.fav-heading {
  line-height: 1.5rem;
}
.fav-content .card-body {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
}

.fav-content .fav-title {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.profile-img {
  height: 200px;
  width: 200px;
  margin: 0 auto;
  /*position: relative;*/
  top: -135px;
  /*border: 1px solid #a9a9a9;*/

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  position: absolute;
}

.profile-img-inner {
  margin: 3px;
  padding: 2px;
  background-color: #cecece;
}

.profile-img img {
  width: 100%;
  border-radius: 3%;
}

.profile-summary {
  position: relative;
  /*top: -180px;*/
  padding: 5rem 2rem 3rem 2rem;
}

.profile-summary h5 {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 3.75px;
  line-height: 34px;
}
.profile-summary p {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.61px;
  line-height: 20px;
}

.recitation-list .rec-body-item {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.92px;
  line-height: 15px;
}

.recitation-list-body h6 {
  color: #26466d;
  font-family: "Avenir Next";
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.08px;
  line-height: 18px;
}

.recent-uploads-wrapper .poem-image,
.profile-details .poem-image {
  max-width: 50px;
  max-height: 50px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

/* Profile CSS Settings End */

/* FAQ CSS Settings Start */
.bg-scroll {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  position: absolute;
  z-index: -1;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 350px;
  opacity: 0.25;
}

.bg-front {
  height: 350px;
}

.App .navbar .faq-search {
  flex-grow: 2;
  max-width: 400px;
}

.list-group-item .faq-answer {
  border: none;
  border-radius: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
/* FAQ CSS Settings End */

/* POST LOGIN HOME PAGE CSS START */

.alice-carousel__stage-item {
  padding: 0 20px;
}

.alice-carousel .alice-carousel__stage {
  margin: 0 -20px !important;
}

.default-recordings-item {
  width: 150px;
  height: 215px;
  border: 1px solid f9e6c7;
}

.featured-recordings-item .card-footer {
  background-color: transparent;
  border: 0;
}

.featured-recordings-item:hover {
}

.featured-recordings-item img,
.default-recording-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.featured-recordings-item h5 {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 1.08px;
}
.featured-recordings-item p {
  font-size: 0.7em;
}

/* Profile Playlist Carousel */

.playlist-item .card-footer {
  background-color: transparent;
  border: 0;
}
.playlist-item img {
  /* width: 148px;  */
  border-radius: 4%;
  width: 100%;
}

.playlist-item h5 {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 1.08px;
}
.playlists-item p {
  font-size: 0.7em;
}

/* POST LOGIN HOME PAGE CSS END */

.card {
  -webkit-box-shadow: 2px -3px 3px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px -3px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px -3px 3px 0 rgba(0, 0, 0, 0.1);
}

/* Media Player CSS Start */
.index_av__background__1Lhbu {
  position: sticky !important;
  width: 100% !important;
}

/* Media Player CSS End*/

/* Carousel */

.carousel-container .carousel-container-arrow {
  /*float: right;*/
}

/* Recitation List */

.recitation-list
  .recitation-list-body
  .recitation-list-body-like
  .recitation-list-body-like-button {
  cursor: pointer;
}

/* Comment */
.comment-list {
  text-align: left;
}

.navbar-brand {
  color: #fffaf0 !important;
  font-family: "Avenir Next" !important;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 1.4px;
  line-height: 19px;
}
