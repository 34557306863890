.sidebar {
  position: fixed;
  height: 100vh;
  top: 80px;
  left: 0;
  background: #2f3a41;
  overflow-y: auto;
}

.sidebar.open {
  width: 250px;
}

.sidebar.closed {
  width: 80px;
}

.sidebar-menu,
.sidebar-menu li {
  padding: 0;
  list-style: none;
}

.sidebar-link {
  display: block;
  padding: 0.8rem 2rem;
  color: #fff;
  text-decoration: none;
  background-color: #2f3a41;
  text-align: left;
}

.sidebar-link:hover {
  text-decoration: none;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-link .icon {
  margin-right: 0.5rem;
}

.sidebar.closed .sidebar-link {
  padding: 0.6rem 0.3rem;
  text-align: center;
}

.sidebar.closed .sidebar-link span {
  display: block;
  font-size: 0.7em;
}

.sidebar.closed .sidebar-link .icon {
  margin-right: 0;
}

.sidebar-divider {
  height: 1px;
  margin: 1rem;
  background-color: #757575;
}

.sidebar-content {
  color: #fff;
  background: #2f3a41;
}

.faq-group + .faq-group {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.faq-group h6 {
  margin-top: 25px;
}
.pdf-button {
  position: relative;
  margin: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  padding: 10px;
  background-color: rgba(34, 62, 96, 1);
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.pdf-button__text {
  font-size: 16px;
  font-weight: bold;
  padding: 0.5rem;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
}
.pdf-button__subtitle {
  font-size: 16px;
  padding: 0;
  margin-top: 10px;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
}

.pdf-button:hover .pdf-button__text {
  opacity: 0.6;
}
.pdf-button:hover .pdf-button__subtitle {
  opacity: 0;
}

.pdf-button__overlay {
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(34, 62, 96, 1);
  display: flex;
  margin-bottom: 3rem;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.pdf-button__overlay--visible {
  opacity: 1;
}

.pdf-button__overlay-text {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  max-width: calc(100vw - 3rem);
}

@media (min-width: 576px) {
  .pdf-button {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .pdf-button:last-child {
    margin-right: 0;
  }
}

.custom-modal-dialog {
  max-width: 80%;
  max-height: 90%;
}
